import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { EvaluationService } from '../service/EvaluationService';
import { PeriodeService } from '../service/PeriodeService';

import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';

import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { FileService } from '../service/FileService';

import { ImportEvaluationService } from '../service/ImportEvaluationService';
import { TypeActiviteService } from '../service/TypeActiviteService';


const EvaluationProf = () => {

    const emptyType = {
        id: null,
        code: '',
        libelle: ''
    }

    const emptyMatiere = {
        id: null,
        code: '',
        libelle: ''
    }

    const emptyClasse = {
        id: null,
        code: '',
        libelle: ''
    }
    const emptyPeriode = {
        id: null,
        code: '',
        libelle: ''
    }
    const emptyAnnee = {
        id: sessionStorage.getItem('AnneEncours')
    }

    const emptyEvaluation = {
        id: null,
        code: '',
        date: '',
        eleve: null,
        heure: '',
        duree: '',
        etat: '',
        note: '',
        noteSur: 0,
        dateLimite: '',
        type: emptyType,
        matiereEcole: emptyMatiere,
        classe: emptyClasse,
        periode: emptyPeriode,
        annee: emptyAnnee,
        user: sessionStorage.getItem('idUser')

    }
    const [globalFilter, setGlobalFilter] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [evaluations, setEvaluations] = useState([]);
    const [evaluation, setEvaluation] = useState(emptyEvaluation);
    const [evaluationImport, setEvaluationImport] = useState(emptyEvaluation);
    const [evalDialog, setEvalDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [classes, setClasses] = useState([]);
    const [distinctClasses, setDistinctClasses] = useState([]);
    const [classe, setClasse] = useState(null);
    const [periodes, setPeriodes] = useState(null);
    const [periodesToCreate, setPeriodesToCreate] = useState(null);
    const [periode, setPeriode] = useState(emptyPeriode);
    const [types, setTypes] = useState(null);
    const [matieres, setMatieres] = useState(null);
    const [matiere, setMatiere] = useState(emptyMatiere);

    const [matiereImport, setMatiereImport] = useState(emptyMatiere);
    const [periodeImport, setPeriodeImport] = useState(emptyPeriode);
    const [noteSurImport, setNoteSurImport] = useState(20);
    const [typeEvalImport, setTypeEvalImport] = useState(emptyType);
    const [dateEvalImport, setDateEvalImport] = useState(null);


    const width = "1000px";

    const [profMatClasseSelect, setProfmatClassesSelect] = useState(null);
    const [profMatClassesMatiereSelect, setProfMatClassesMatiereSelect] = useState(null);

    const [disabled, setdisabled] = useState(true);
    const userId = sessionStorage.getItem('CandidatId');
    const anneeId = sessionStorage.getItem('AnneEncours');

    const [importedData, setImportedData] = useState([]);
    const [selectedImportedData, setSelectedImportedData] = useState([]);
    const [importedCols, setImportedCols] = useState([{ field: '', header: '' }]);

    const fileService = new FileService();
    // const [maxEval, setEvalEleve] = useState(null);
    const [evalEleves, setEvalEleves] = useState([]);
    const [disabledImportSaveBtn, setDisabledImportSaveBtn] = useState(true);
    const [recapDialog, setRecapDialog] = useState(false);
    const [recapData, setRecapData] = useState([]);
    const [disabledUpdate, setDisabledUpdate] = useState(false);
    const [lblBtnSave, setLblBtnSave] = useState('');

    const location = useLocation();
    const data = location.state;
    let history = useHistory();
    useEffect(async () => {

        const periodeService = new PeriodeService();
        const typeActiviteService = new TypeActiviteService();
        const persMatClasService = new PersonnelMatiereClasseService();


        persMatClasService.getByProfesseur(sessionStorage.getItem('AnneEncours'), userId, sessionStorage.getItem('EcoleID')).then(res => {
            setProfmatClassesSelect(res);

            const filter = [];
            periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(res => setPeriodes(res));
            typeActiviteService.getListByEcoleAndType(sessionStorage.getItem('EcoleID'), 'EVAL').then(res => setTypes(res));
            for (let index = 0; index < res.length; index++) {
                if (!filter.includes(res[index].classe.id)) {
                    filter.push(res[index].classe.id);
                    distinctClasses.push(res[index].classe);
                }
            }
            // cas de retour (bouton precedent) via la page des notes
            if (typeof (data) !== 'undefined' && data !== null) {
                // console.log('coucou data ::: '+typeof (data));
                initFromNotesPage();
            }
        });

    }, []);

    const initFromNotesPage = () => {
        // console.log(data);
        const persMatClasService = new PersonnelMatiereClasseService();
        let _profMatClassesMatiereSelect = [];
        persMatClasService.getByProfesseurAndClasse(userId, data?.classe?.id, sessionStorage.getItem('AnneEncours')).then(res => {
            _profMatClassesMatiereSelect = _profMatClassesMatiereSelect.concat(res);
            setProfMatClassesMatiereSelect(_profMatClassesMatiereSelect);
            setMatiere(data.matiereEcole);
            setClasse(data.classe);
            setPeriode(data.periode);
            setdisabled(false);
            const evaluationService = new EvaluationService();
            evaluationService.getByClasseAndMatiereAndPeriode(data?.classe?.id, data?.matiereEcole?.id, data?.periode?.id, sessionStorage.getItem("AnneEncours")).then(res => {
                setEvaluations(res);
            });

        });
        // console.log(evaluation)

    }


    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _evaluation = { ...evaluation };
        _evaluation[`${name}`].id = val;
        setEvaluation(_evaluation);

        if (name === 'classe') {
            const persMatClasService = new PersonnelMatiereClasseService();
            let _matieres = [];
            // console.log('prof-id est ::: ' + userId);
            persMatClasService.getByProfesseurAndClasse(userId, val, sessionStorage.getItem('AnneEncours')).then(res => {
                for (let i = 0; i < res.length; i++) {
                    // console.log(res[i].matiere);
                    _matieres.push(res[i].matiere);
                }
                setMatieres(_matieres);
            });

        }
        // console.log(_evaluation);
    }

    const onSearchSelectImportChange = (e, name) => {
        let val = e.target.value;
        let _evaluationImport = { ...evaluationImport };
        _evaluationImport[`${name}`].id = val;
        setEvaluationImport(_evaluationImport);
        console.log(_evaluationImport);

    }

    const onSearchInputImportChange = (e, name) => {
        let val = e.value;
        let _evaluationImport = { ...evaluationImport };
        _evaluationImport[`${name}`] = val;
        setEvaluationImport(_evaluationImport);
        console.log(_evaluationImport);

    }

    const onSearchSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (name === 'classe') {
            const persMatClasService = new PersonnelMatiereClasseService();
            let _classe = { ...classe };
            let _profMatClassesMatiereSelect = [];
            _classe.id = val;
            setClasse(_classe);
            persMatClasService.getByProfesseurAndClasseWhereCoefIsDefine(userId, val, sessionStorage.getItem('AnneEncours')).then(res => {
                _profMatClassesMatiereSelect = _profMatClassesMatiereSelect.concat(res);
                setProfMatClassesMatiereSelect(_profMatClassesMatiereSelect);
            }
            );
            // console.log(_profMatClassesMatiereSelect);
        }

        if (name === 'matiere') {
            let _matiere = { ...matiere };
            _matiere.id = val;
            setMatiere(_matiere);
            if (periode.id !== null && _matiere.id !== '')
                setdisabled(false);
            else
                setdisabled(true);
        }

        if (name === 'matiereImport') {
            let _matiere = { ...matiereImport };
            _matiere.id = val;
            setMatiereImport(_matiere);
            console.log(matiereImport);
            evaluationImport[`${name}`].id = val;
        }


        if (name === 'periode') {
            let _periode = { ...periode };
            _periode.id = val;
            setPeriode(_periode);

            if (_periode.id !== null && matiere.id !== null)
                setdisabled(false);
            else
                setdisabled(true);
        }

        if (name === 'periodeImport') {
            let _periode = { ...periode };
            _periode.id = val;
            setPeriodeImport(_periode);
        }

    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _evaluation = { ...evaluation };
        _evaluation[`${name}`] = val;
        setEvaluation(_evaluation);
        // console.log('+++++++'+evaluation.noteSur+'+++'+val);
    }

    const editEvaluation = (rowData) => {
        setSubmitted(false);
        setEvalDialog(true);
        setDisabledUpdate(true);
        // console.log(rowData.date);
        let date = new Date(rowData.date.split('Z')[0]);
        let rowDataTmp = { ...rowData }
        console.log(new Date(rowData.date.split('Z')[0]));
        rowDataTmp.date = date;
        rowDataTmp.user = sessionStorage.getItem('idUser');
        // console.log(new Date(rowData.date.split('Z')[0]));
        setEvaluation({ ...rowDataTmp });
        const persMatClasService = new PersonnelMatiereClasseService();
        let _matieres = [];
        if (classe.id) {
            persMatClasService.getByProfesseurAndClasse(userId, classe.id, sessionStorage.getItem('AnneEncours')).then(res => {
                for (let i = 0; i < res.length; i++) {
                    // console.log(res[i].matiere);
                    _matieres.push(res[i].matiere);
                }
                setMatieres(_matieres);
            });

        }
        // console.log(profMatClassesMatiereSelect);
        setLblBtnSave('Modifier');
        persMatClasService.getByProfesseur(sessionStorage.getItem("AnneEncours"), sessionStorage.getItem('CandidatId'), sessionStorage.getItem('EcoleID')).then(res => {
            let _classes = [];
            let filter = [];
            for (let i = 0; i < res.length; i++) {
                if (!filter.includes(res[i].classe.code)) {
                    filter.push(res[i].classe.code)
                    _classes.push(res[i].classe);
                }
            }
            setClasses(_classes);
            // console.log(_classes);
        }
        );

    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {formatDate(rowData.date)}
            </>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.numero}
            </>
        );
    }

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Evaluation</span>
                {rowData.type != null ? rowData.type.libelle : ''}
            </>
        );
    }

    const periodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Période</span>
                {rowData.periode != null ? rowData.periode.libelle : ''}
            </>
        );
    }


    const classeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.classe != null ? rowData.classe.libelle : ''}
            </>
        );
    }


    const matiereBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData.matiereEcole != null ? rowData.matiereEcole.libelle : ''}
            </>
        );
    }

    const noteSurBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">noteSur</span>
                {rowData.noteSur}
            </>
        );
    }

    const displayEvaluationNotes = (rowData) => {
        routeChange('/home/professionnelle/evaluationsprof/notes/' + rowData.code);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <a href={'#/home/professionnelle/evaluationsprof/notes/' + rowData.code} >Voir détail</a> */}
                <Button icon="pi pi-list" className="p-button-rounded p-button-outlined mr-2" tooltip='Consulter' onClick={() => displayEvaluationNotes(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editEvaluation(rowData)} />
            </div>
        );
    }

    const pecBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prise en compte dans le calcul des moyennes</span>
                <InputSwitch name='pec' className='ml-2' trueValue={1} falseValue={0} checked={rowData.pec} onChange={(e) => { setPecValue(e.value, rowData) }} />
            </>
        );
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Importer" icon="pi pi-file" className="p-button-info mx-2" onClick={openImport} disabled={!classe?.id} />
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }



    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        } else
            return '';
    }
    const openNew = () => {
        setSubmitted(false);
        setEvalDialog(true);
        setEvaluation({ ...emptyEvaluation })
        setLblBtnSave('Enregistrer');
        const persMatClasService = new PersonnelMatiereClasseService();
        persMatClasService.getByProfesseur(sessionStorage.getItem("AnneEncours"), sessionStorage.getItem('CandidatId'), sessionStorage.getItem('EcoleID')).then(res => {
            let _classes = [];
            let filter = [];
            for (let i = 0; i < res.length; i++) {
                if (!filter.includes(res[i].classe.code)) {
                    filter.push(res[i].classe.code)
                    _classes.push(res[i].classe);
                }
            }
            setClasses(_classes);
            // console.log(_classes);
        }
        );

        const periodeService = new PeriodeService();
        periodeService.getListPeriodicitePastAndCurrent(sessionStorage.getItem('periodiciteId'),
            sessionStorage.getItem('EcoleID')).then(resp => {
                console.log(resp);
                setPeriodesToCreate(resp);
            }
            )
        // setHeaderTitle("Créer une nouvelle évaluation");
    }

    const openImport = () => {
        setImportDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEvalDialog(false);
    }

    const hideImportDialog = () => {
        setImportDialog(false);
        //clear();
    }


    const routeChange = (path) => {
        history.push(path);
    }

    const hideRecapDialog = () => {

        setPeriodeImport(emptyPeriode);
        setPeriodeImport([]);
        setRecapDialog(false);
        setRecapData([]);
        setSubmitted(false);
        //clear();
    }

    const reactfindIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < evaluations.length; i++) {
            if (evaluations[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const findClasseById = (id) => {
        let current = {};
        for (let i = 0; i < distinctClasses.length; i++) {
            if (distinctClasses[i].id === id) {
                current = { ...distinctClasses[i] };
                break;
            }
        }
        // console.log(current);
        return current;
    }

    const save = () => {
        setSubmitted(true);
        // console.log(evaluation);
        // console.log(evaluation.type.id != null);
        // _evaluation.user = sessionStorage.getItem('idUser');
        if (evaluation.type.id != null && evaluation.periode.id != null && evaluation.matiereEcole.id != null && evaluation.classe.id != null && evaluation.noteSur != '' && evaluation.date != '' && evaluation.duree != '') {
            let _evaluations = [...evaluations];
            let _evaluation = { ...evaluation };
            console.log(_evaluation);
            const evaluationService = new EvaluationService();
            if (evaluation.id) {
                // console.log(evaluation);
                const index = reactfindIndexById(evaluation.id);

                (evaluationService.updateAndDisplay(_evaluation)).then(resp => {
                    _evaluations[index] = { ...resp };
                    setEvaluations(_evaluations);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Evaluation modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole- evaluation - save' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'erreur', summary: 'erreur', detail: error.message, life: 3000 });
                }
                );

            }
            else {
                // _evaluation.annee = createId();
                // console.log(_evaluation);
                (evaluationService.saveAndDisplay(_evaluation)).then(res => {

                    if (_evaluations.length > 0) {
                        if (_evaluations[0].annee.id == res.annee.id && _evaluations[0].classe.id == res.classe.id && _evaluations[0].matiereEcole.id == res.matiereEcole.id && _evaluations[0].periode.id == res.periode.id) {

                            _evaluations.push(res);
                            setEvaluations(_evaluations);
                        }
                    }
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle evaluation créee ' + _evaluation.code, life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                });
            }
            setSubmitted(false);
            setEvaluation(emptyEvaluation);
            setEvalDialog(false);
        }
    }
    const importation = () => {
        console.log('lancement importation');
        let importService = new ImportEvaluationService();
        console.log(evalEleves.length);
        importService.importData(evalEleves, classe.id, anneeId).then(
            resp => {
                // toast.current.show({ severity: 'success', summary: resp.title, detail: resp.detail, life: 3000 });
                clear();
                setDisabledImportSaveBtn(true);
                hideImportDialog()
                setRecapDialog(true);
                setRecapData(resp);
                console.log(resp);
            }
        ).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
    }

    const getEvalWithNewPecValue = (rowData, val) => {
        rowData.pec = val
        return rowData;
    }

    const getToogleCrtl = () => {
        let i = 0;
        evaluations.forEach((ev) => {
            if (ev.pec === 1) {
                i++;
            }
        })

        return i < 15;
    }

    const search = () => {
        const evaluationService = new EvaluationService();
        evaluationService.getByClasseAndMatiereAndPeriode(classe.id, matiere.id, periode.id, sessionStorage.getItem("AnneEncours")).then(res => {
            setEvaluations(res);
            if (res.length == 0)
                toast.current.show({ severity: 'warn', summary: 'Aucune donnée trouvée', detail: '', life: 3000 });

        });

    }

    const setPecValue = (val, rowData) => {
        let index = reactfindIndexById(rowData.id)
        const evaluationService = new EvaluationService();
        // console.log('index '+index);


        if (getToogleCrtl() || val === 0) {
            evaluationService.updateAndDisplay(getEvalWithNewPecValue(rowData, val)).then(
                resp => {
                    let _evaluations = [...evaluations];
                    _evaluations[index].pec = val;
                    setEvaluations(_evaluations);
                    // console.log(resp);
                    // echo();
                }
            ).catch((error) => {
                if (error.response) {
                    console.log('erreur response- vie ecole' + error.response.status);
                } else if (error.request) {
                    console.log('erreur request- vie ecole' + error.request);
                } else {
                    console.log('Error autres', error.message);
                }
                toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
            });
        } else {
            toast.current.show({ severity: 'error', summary: 'Attention', detail: 'Nombre de selection atteint', life: 3000 });
        }
    }

    const cleanTab = (data) => {
        let obj = [];
        for (let i = 0; i < data.length; i++) {
            //    console.log(data[i]);
            if (typeof (data[i].matricule) !== 'undefined') {
                obj.push(data[i]);
            }
        }
        return obj;
    }

    const cleanImportedData = (data) => {
        let obj = [];
        for (let i = 0; i < data.length; i++) {
            //    console.log(data[i]);
            if (typeof (data[i].Matricule) !== 'undefined') {
                obj.push(data[i]);
            }
        }
        return obj;
    }

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const importExcel = (e) => {
        // console.log('coucou file import');
        let tab = [];
        const file = e.files[0];
        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
                // Prepare DataTable

                // console.log('size list ' + evalEleves.length);
                const cols = data[0];
                data.shift();
                let i = 0;
                let _evalEleve = {};
                let _notes = [];
                let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                let _importedData = data.map(d => {
                    // console.log('----back -----');
                    // console.log(evalEleves);

                    i = 0;
                    if (i != 0)
                        _evalEleve = {};
                    _notes = [];
                    // console.log('size '+cols.length)
                    return cols.reduce((obj, c, i) => {
                        // console.log('---> debut '+i+' col '+c);
                        if (i == 0) {
                            _evalEleve.matricule = d[i];
                        } else if (i < (cols.length - 1)) {
                            _notes.push(d[i]);
                        } else {
                            _notes.push(d[i]);
                            _evalEleve.notes = _notes.slice();
                            // Faire une copie par valeur et non par reference
                            tab.push(Object.assign({}, _evalEleve));
                        }

                        // console.log(_evalEleve);
                        obj[c] = d[i];
                        // console.log('<--- fin '+i+' col '+c+' ---');
                        i++;
                        return obj;
                    }, {});
                });

                // console.log("------------------------------------------------------");

                // let _evalEleve = { ...evalEleve }
                setEvalEleves(cleanTab(tab));
                setImportedCols(_importedCols);
                setImportedData(cleanImportedData(_importedData));
                if (tab.length > 0)
                    setDisabledImportSaveBtn(false);
            };

            reader.readAsArrayBuffer(file);
        });
        // setEvalEleves([]);
        // console.log('size list fin'+ evalEleves.length);
    }

    const clear = () => {
        setImportedData([]);
        setSelectedImportedData([]);
        setImportedCols([{ field: '', header: 'En-tete' }]);
        setDisabledImportSaveBtn(true);
    }
    const onImportSelectionChange = (e) => {
        setSelectedImportedData(e.value);
        const detail = e.value.map(d => Object.values(d)[0]).join(', ');
        toast.current.show({ severity: 'info', summary: 'Ligne selectionnée', detail, life: 3000 });
    }

    // async function buildParam(key, value) {
    //     key.evaLoaders = value;
    //     return  key;
    // }

    const appliquer = () => {

        setSubmitted(true);
        if (evaluationImport.periode?.id && evaluationImport.noteSur && evaluationImport.type?.id && evaluationImport.date) {
            let importService = new ImportEvaluationService();
            // let _payLoad = await buildParam(evaluationImport, recapData)
            importService.applyData(recapData, evaluationImport).then(
                resp => {
                    toast.current.show({ severity: 'success', summary: 'Application du chargement', detail: "Opération bien effectuée", life: 3000 })
                    hideRecapDialog();
                }

            ).catch((error) => {
                if (error.response) {
                    console.log('erreur response- vie ecole' + error.response.status);
                } else if (error.request) {
                    console.log('erreur request- vie ecole' + error.request);
                } else {
                    console.log('Error autres', error.message);
                }
                toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
            });
            setSubmitted(false);
        }

    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Evaluations</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const headerEvalDialog = (
        <div>
            <h4>Importer des évaluations de la classe {findClasseById(classe?.id).libelle}</h4>
        </div>
    )
    const headerEvaluationDialog = (
        <div>
            <h4>{lblBtnSave} une évaluation</h4>
        </div>
    );

    const headerAppliquerDialog = (
        <div className='border-bottom-3'>
            <h4>APPLIQUER LE CHARGEMENT</h4>
        </div>
    );
    const evalDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={lblBtnSave} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const importDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideImportDialog} />
            <Button label="Enregistrer" icon="pi pi-check" disabled={disabledImportSaveBtn} onClick={importation} />
        </>
    );

    const notesBody = (rowData, column) => {
        return (
            <>
                {
                    rowData.noteloaders[parseInt(column.column.props.header.split(' ')[1]) - 1].note
                }
            </>
        )
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="grid card py-7">

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
                        <Dropdown id="classe" value={classe?.id} onChange={(e) => onSearchSelectChange(e, 'classe')} options={distinctClasses}
                            required optionValue="id" style={{ width: '250px' }} optionLabel="libelle" placeholder="Selectionner la classe" />
                    </div>

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="matiere"><b>Matière</b></label>
                        <Dropdown id="matiere" style={{ width: '250px' }} value={matiere?.id} onChange={(e) => onSearchSelectChange(e, 'matiere')} options={profMatClassesMatiereSelect}
                            required optionValue="matiere.id" optionLabel="matiere.libelle" placeholder="Selectionner la matière" />
                    </div>

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="periode"><b>Période</b></label>
                        <Dropdown id="periode" style={{ width: '250px' }} value={periode?.id} onChange={(e) => onSearchSelectChange(e, 'periode')} options={periodes}
                            required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" />
                    </div>

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0 mb-0 mt-auto">
                        <Button label="Rechercher" icon="pi pi-search" disabled={disabled} onClick={search} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={evaluations}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} evaluations"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column field="numero" header="N°" sortable body={idBodyTemplate} headerStyle={{ minWidth: '3rem' }}></Column>
                        <Column field="dateToFilter" header="Date" sortable body={dateBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        <Column field="type" header="Evaluation" sortable body={typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column field="classe" header="Classe" sortable body={classeBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="matiere" header="Matière" body={matiereBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="periode.libelle" header="Periode" sortable body={periodeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column field="noteSur" header="Noté sur" sortable body={noteSurBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column field="pec" header="P.E.C" sortable body={pecBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }} tooltip="Prendre en compte dans le calcul des moyennes"></Column>
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '20%', minWidth: '9rem' }}></Column>
                    </DataTable>

                </div>
            </div>

            <Dialog visible={evalDialog} style={{ width: width }} header={headerEvaluationDialog} modal className="p-fluid" footer={evalDialogFooter} onHide={hideDialog} >

                <div className="formgroup-inline">
                    <div className="row">

                        <div className="field col-lg-4 cl-md-6 col-12">
                            <label htmlFor="type"><b>Type d'évaluation</b></label>
                            <Dropdown id="type" value={evaluation.type.id} onChange={(e) => onSelectChange(e, 'type')} options={types} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le type" className={classNames({ 'p-invalid': submitted && !(evaluation.type.id) })} />
                            {submitted && !(evaluation.type.id) && <small className="p-invalid">Le type est requis.</small>}
                        </div>

                        <div className="field col-lg-4 cl-md-6 col-12">
                            <label htmlFor="periode"><b>Periode</b></label>
                            <Dropdown id="periode"  emptyMessage="Date limite d'insertion dépassée" value={evaluation.periode.id} onChange={(e) => onSelectChange(e, 'periode')} options={periodesToCreate} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(evaluation.periode.id) })} />
                            {submitted && !(evaluation.periode.id) && <small className="p-invalid">La période est requise.</small>}
                        </div>

                        <div className="field col-lg-4 cl-md-6 col-12">
                            <label htmlFor="classe"><b>Classe</b></label>
                            <Dropdown id="classe" value={evaluation.classe.id} disabled={disabledUpdate}  onChange={(e) => onSelectChange(e, 'classe')} options={classes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le type" className={classNames({ 'p-invalid': submitted && !(evaluation.classe.id) })} />
                            {submitted && !(evaluation.classe.id) && <small className="p-invalid">La classe est requise.</small>}
                        </div>
                    </div>
                </div>
                <div className='formgroup-inline_ row'>

                    <div className='field col-6'>
                        <label htmlFor='matiere'><b>Matière</b></label>
                        <Dropdown id='matiere' value={evaluation.matiereEcole.id} onChange={(e) => onSelectChange(e, 'matiereEcole')} options={matieres} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(evaluation.matiereEcole.id) })}></Dropdown>
                        {submitted && !evaluation.matiereEcole.id && <small className="p-invalid">La matière est requise.</small>}
                    </div>
                    <div className='field col-6'>
                        <label htmlFor='notesur'><b>Noté sur</b></label>
                        <InputNumber value={evaluation.noteSur} onValueChange={(e) => onInputChange(e, 'noteSur')} id="notesur" required min={0} max={100} showButtons mode="decimal" className={classNames({ 'p-invalid': submitted && !evaluation.noteSur })} />
                        {submitted && !evaluation.noteSur && <small className="p-invalid">La note est requise.</small>}
                    </div>

                </div>
                <div className='formgroup-inline_ row'>
                    <div className='field col-6'>
                        <label htmlFor='date'><b>Date</b></label>
                        <Calendar id='date' showIcon showTime showButtonBar value={evaluation.date} dateFormat="dd/mm/yy" onChange={(e) => onInputChange(e, 'date')} className={classNames({ 'p-invalid': submitted && !evaluation.date })}></Calendar>
                        {submitted && !evaluation.date && <small className="p-invalid">La date est requise.</small>}
                    </div>

                    {/* <div className='field col-3'>
                        <label htmlFor='heure'><b>Heure</b></label>
                        <Calendar id='heure' showIcon timeOnly hourFormat="24" icon='pi pi-fw pi-clock' value={evaluation.heure} onChange={(e) => onInputChange(e, 'heure')} className={classNames({ 'p-invalid': submitted && !evaluation.heure })}></Calendar>
                        {submitted && !evaluation.heure && <small className="p-invalid">L&apos;heure est requise.</small>}
                    </div> */}

                    <div className='field col-6'>
                        <label htmlFor='duree'><b>durée</b></label>
                        {/* <Calendar id='duree' showIcon timeOnly icon='pi pi-fw pi-clock' value={evaluation.duree} onChange={(e) => onInputChange(e, 'duree')} className={classNames({ 'p-invalid': submitted && !evaluation.duree })}></Calendar> */}
                        <InputMask id="duree" mask="99-99" value={evaluation.duree} placeholder="hh-mm" onChange={(e) => onInputChange(e, 'duree')} className={classNames({ 'p-invalid': submitted && !evaluation.duree })}></InputMask>
                        {submitted && !evaluation.duree && <small className="p-invalid">La durée est requise.</small>}
                    </div>
                </div>
            </Dialog>

            <Dialog visible={importDialog} style={{ width: '85%', minHeight: '70%' }} header={headerEvalDialog} footer={importDialogFooter} modal className="p-fluid" onHide={hideImportDialog}>

                <div className="grid m-2">
                    {/* <FileUpload chooseOptions={{ label: 'CSV', icon: 'pi pi-file' }} mode="basic" name="demo[]" auto url="https://primefaces.org/primereact/showcase/upload.php" accept=".csv" className="p-mr-2" onUpload={importExcel} /> */}
                    <div className='col-1'>
                        <FileUpload chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }} mode="basic" name="demo" auto url={fileService.getUrlFileSend}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onUpload={importExcel} />
                    </div>
                    <div className='col-1'>
                        <Button type="button" label="Effacer" icon="pi pi-times" onClick={clear} className="p-button-danger" />
                    </div>
                </div>

                <DataTable value={importedData} emptyMessage="Aucune donnée" paginator rows={10} alwaysShowPaginator={false} responsiveLayout="scroll"
                    selectionMode="multiple" selection={selectedImportedData} onSelectionChange={onImportSelectionChange}>

                    {
                        importedCols.map((col, index) => <Column key={index} field={col.field} header={col.header} />)
                    }
                </DataTable>

            </Dialog>

            <Dialog visible={recapDialog} style={{ width: '85%', minHeight: '70%' }} header={headerAppliquerDialog} modal className="p-fluid" onHide={hideRecapDialog} >

                <div className="grid m-2">
                    <div className='bg-green-500 col-6 border-round'>
                        <h3 className='text-white'>RECAPITULATIF AVANT APPLICATION DU CHARGEMENT</h3>
                    </div>
                    <div className='col-6 grid'>

                        <div className='col-4'>
                            <Dropdown id="matiereImport" value={evaluationImport.matiereEcole?.id} onChange={(e) => onSearchSelectImportChange(e, 'matiereEcole')} options={profMatClassesMatiereSelect}
                                required optionValue="matiere.id" optionLabel="matiere.libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(evaluationImport.matiereEcole?.id) })} /></div>
                        <div className='col-4'>
                            <Dropdown id="periode" value={evaluationImport.periode?.id} onChange={(e) => onSearchSelectImportChange(e, 'periode')} options={periodesToCreate}
                                required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(evaluationImport.periode?.id) })} />
                        </div>
                        <div className='col-4'>
                            <Button type="button" label="Appliquer" icon="pi pi-check-square" onClick={appliquer} className="p-button-success" />
                        </div>

                        <div className='field col-4 pt-4'>
                            <label htmlFor='noteSur' className='mr-2'><b>Noté sur</b></label>
                            <InputNumber id="noteSur" value={evaluationImport.noteSur} min={0} style={{ width: '100px' }} onChange={(e) => onSearchInputImportChange(e, 'noteSur')}
                                required className={classNames({ 'p-invalid': submitted && !(evaluationImport.noteSur) })} /></div>
                        <div className='col-4'>
                            <label htmlFor='dateEval' className='mr-2'><b>Date de l&apos;&eacute;valuation</b></label>
                            <Calendar id="dateEval" icon="pi pi-calendar" value={evaluationImport.date} onChange={(e) => onSearchInputImportChange(e, 'date')} className={classNames({ 'p-invalid': submitted && !(evaluationImport.date) })} />
                        </div>
                        <div className='col-4 pt-4'>
                            <Dropdown id="typeEval" value={evaluationImport.type.id} onChange={(e) => onSearchSelectImportChange(e, 'type')} options={types}
                                required optionValue="id" optionLabel="libelle" placeholder="Selectionner le type" className={classNames({ 'p-invalid': submitted && !(evaluationImport.type.id) })} />
                        </div>
                    </div>

                </div>
                <DataTable value={recapData} emptyMessage="Aucune donnée" paginator rows={10} alwaysShowPaginator={false} responsiveLayout="scroll"
                    selectionMode="multiple" selection={selectedImportedData} onSelectionChange={onImportSelectionChange}>

                    <Column field="matricule" header="Matricule" ></Column>
                    <Column field="nomPrenom" header="Nom & Prenom" ></Column>

                    {
                        recapData[0]?.noteloaders.map((col, index) =>
                            <Column key={index} header={"Note " + (parseInt(index) + 1)} body={notesBody} ></Column>)
                    }
                    <Column field="observation" header="Observation" ></Column>

                </DataTable>
            </Dialog>
        </div>

    );
}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EvaluationProf, comparisonFn);
