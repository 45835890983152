import React, { useEffect, useState, useRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SeanceService } from '../service/SeanceService';
import { ClasseService } from '../service/ClasseService';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Calendar } from 'primereact/calendar';
import { MatiereEcoleService } from '../service/MatiereEcoleService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const ProgressionStat = () => {

  const [seancesStat, setSeancesStats] = useState({});
  const [seances, setSeances] = useState({});
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(10);
  const [first, setFirst] = useState(0);
  const [classe, setClasse] = useState(null)
  const [classes, setClasses] = useState([])
  const [matiere, setMatiere] = useState(null)
  const [matieres, setMatieres] = useState([])
  const [dateDebut, setDateDebut] = useState(null)
  const [dateFin, setDateFin] = useState(null)
  const [openDetail, setOpenDetail] = useState(false)
  const [detail, setDetail] = useState({})

  useEffect(() => {
    const seanceService = new SeanceService();
    const classeService = new ClasseService();
    const matiereEcoleService = new MatiereEcoleService();
    classeService.getSortedListByEcole(sessionStorage.getItem('EcoleID')).then(res => {
      setClasses(res);
    })
    matiereEcoleService.getByEcoleViaNiveauEnseignement(sessionStorage.getItem('EcoleID')).then(res => {
      setMatieres(res)
    })
    seanceService.getListStatSeanceByAnneeAndEcole(sessionStorage.getItem('AnneEncours'),
      sessionStorage.getItem('EcoleID')).then(res => {
        setSeancesStats(res)
      })

    seanceService.getDtoResponseSearchByEcoleAndDate(sessionStorage.getItem('EcoleID'), formatDateToString(new Date()), 0, rows).then(res => {
      setSeances(res)
      setTotalRecords(res.total)
    })
  }, []);

  function formatDateToString(date) {
    if (!(date instanceof Date) || isNaN(date)) {
      throw new Error("Invalid date provided");
    }

    const day = String(date.getDate()).padStart(2, '0'); // Jour avec deux chiffres
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois avec deux chiffres
    const year = date.getFullYear(); // Année

    return `${day}-${month}-${year}`;
  }
  let contentSeance = (
    <div className='grid'>
      <div className='col-10'>
        Total General:
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalGeneral}
      </div>

      <div className='col-10'>
        Total du jour :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalJour}
      </div>
    </div>
  )

  let contentAppel = (
    <div className='grid'>
      <div className='col-10'>
        Total General:
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalAppelGeneral}
      </div>

      <div className='col-10'>
        Total général effectués :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalAppelGeneralEffectue}
      </div>

      <div className='col-10'>
        Total du jour :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalAppelJour}
      </div>

      <div className='col-10'>
        Total du jour effectué :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalAppelJourEffectue}
      </div>

    </div>
  )

  let contentCahierTexte = (
    <div className='grid'>
      <div className='col-10'>
        Total General:
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalCTGeneral}
      </div>

      <div className='col-10'>
        Total général renseigné :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalCTGeneralEffectue}
      </div>

      <div className='col-10'>
        Total du jour :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalCTJour}
      </div>

      <div className='col-10'>
        Total du jour renseigné :
      </div>
      <div className='col-2 font-bold'>
        {seancesStat.totalCTJourEffectue}
      </div>

    </div>
  )

  const block = (title, content, color) => {
    return (
      <div className='lg:col-4 md:col-4 sm:col-12 p-1 text-white'>
        <div className={`bg-${color}-800 border-round-3 shadow-3 border-600 mx-2 pl-3 font-bold text-xl`}
          style={{ "minHeight": "40px", "borderTopLeftRadius": "0.7rem", "borderTopRightRadius": "0.7rem" }}>
          {title}
        </div>
        <div className={`bg-${color}-700 border-round-3 shadow-3 border-600 mx-2 pl-2`}
          style={{ "minHeight": "100px", "borderBottomLeftRadius": "0.7rem", "borderBottomRightRadius": "0.7rem" }}>
          {content}
        </div>
      </div>
    )
  }

  let heureBody = (rowData) => {
    return (
      rowData.heureDebut + ' - ' + rowData.heureFin
    )
  }

  let displayDetail = (rowData) => {
    setDetail(rowData)
    setOpenDetail(true)

  }

  let actionBody = (rowData) => {
    return (
      <Button style={{ height: '25px', width: '100px' }} label='Voir detail' onClick={() => displayDetail(rowData)}></Button>
    )
  }

  let isOk = (rowData, field) => {
    let icon = <i className="pi pi-check p-mr-2" style={{ 'color': 'green' }}></i>;
    if (!rowData[`${field}`]) {
      icon = <i className="pi pi-times" style={{ 'color': 'red' }}></i>
    }
    return (
      icon
    )
  }

  const onPageChangeDef = (e) => {

    setRows(e.rows);
    setFirst(e.first)
    setPage(e.page)
    const seanceService = new SeanceService();
    if (matiere || classe || dateDebut || dateFin) {
      seanceService.getDtoResponseSearchByEcoleAndDateAndCriteria(sessionStorage.getItem('EcoleID'), matiere, classe, dateDebut, dateFin, e.page, e.rows).then(res => {
        // console.log()
        setSeances(res)
        setTotalRecords(res.total)
      })

    } else {
      seanceService.getDtoResponseSearchByEcoleAndDate(sessionStorage.getItem('EcoleID'), formatDateToString(new Date()), e.page, e.rows).then(res => {
        console.log(res)
        setSeances(res)
        setTotalRecords(res.total)
      })
    }

  }
  const onSelectDate = (e, field) => {
    if (field === 'dateDebut') {
      setDateDebut(e.value)
    }
    if (field === 'dateFin') {
      setDateFin(e.value)
    }
  }
  const template = {
    layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 'All', value: options.totalRecords }
      ];

      return (
        <React.Fragment>
          <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Nombre d'éléments par page: </span>
          <Dropdown style={{ 'width': '20px' }} value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const templatePanel = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Filtre <i className="pi pi-filter-fill p-mr-2" />
        </span>
      </div>
    )
  }

  const onSelectChange = (e, field) => {
    if (field === 'classe') {
      setClasse(e.target.value)
    }
    if (field === 'matiere') {
      setMatiere(e.target.value)
    }

  }

  const search = () => {
    const seanceService = new SeanceService();
    seanceService.getDtoResponseSearchByEcoleAndDateAndCriteria(sessionStorage.getItem('EcoleID'),
      matiere, classe, dateDebut, dateFin, 0, rows).then(res => {
        // console.log(res)
        setSeances(res)
        setTotalRecords(res.total)
      })
  }

  const reinitSearchFields = () => {
    setMatiere(null)
    setClasse(null)
    setDateDebut(null)
    setDateFin(null)
  }

  const hideDetail = () => {
    setOpenDetail(false);
    setDetail({})
  }

  function getReadableDate(dateString) {
    // Vérifier si le format est correct
    const [day, month, year] = dateString.split('-').map(Number);
    if (!day || !month || !year) {
      throw new Error("Date invalide, utilisez le format dd-mm-yyyy.");
    }

    // Créer un objet Date à partir des parties
    const date = new Date(year, month - 1, day); // Mois commence à 0

    if (isNaN(date)) {
      throw new Error("La date fournie n'est pas valide.");
    }

    // Liste des jours et mois en français
    const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    const months = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];

    // Obtenir le jour de la semaine, le jour et le mois
    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];

    // Retourner la date lisible
    return `${dayName} ${day} ${monthName} ${year}`;
  }

  
  return (
    <div>
      <div className='grid'>
        {block('Séances', contentSeance, 'blue')}
        {block('Appels numériques', contentAppel, 'orange')}
        {block('Cahier de texte', contentCahierTexte, 'green')}
      </div>

      <Panel headerTemplate={templatePanel} toggleable>
        <div className='grid'>
          <div className='md:col-3 lg:col-3 sm-col-12'>
            Classe
            <Dropdown className='border-round' value={classe} options={classes} placeholder='Selectionner la classe'
              onChange={(e) => onSelectChange(e, 'classe')} optionValue='id' optionLabel="libelle" />
          </div>
          <div className='md:col-3 lg:col-3 sm-col-12'>
            Matiere
            <Dropdown className='border-round' value={matiere} options={matieres} placeholder='Selectionner la matière'
              onChange={(e) => onSelectChange(e, 'matiere')} optionValue='id' optionLabel="libelle" />
          </div>
          <div className='md:col-3 lg:col-3 sm-col-12'>
            <div>Du</div>
            <Calendar value={dateDebut} onChange={(e) => onSelectDate(e, 'dateDebut')} dateFormat="dd/mm/yy" />
          </div>
          <div className='md:col-3 lg:col-3 sm-col-12'>
            <div>Au</div>
            <Calendar value={dateFin} onChange={(e) => onSelectDate(e, 'dateFin')} dateFormat="dd/mm/yy" />
          </div>
          <div className='md:col-3 lg:col-3 sm-col-12'>
            <Button className='p-button-success m-1' label='Rechercher' icon="pi pi-search" iconPos="right" onClick={(e) => search()} />
            <Button className='p-button-warning m-1' label='Réinitialiser' onClick={() => reinitSearchFields()} />
          </div>

        </div>
      </Panel>

      <DataTable className='mt-3' value={seances.list} emptyMessage='Aucune donnée trouvée'>
        <Column className='w-1' field='date' header='Date' ></Column>
        <Column className='w-1' header='Heure' body={heureBody}></Column>
        <Column className='w-2' field='classeLibelle' header='Classe'></Column>
        <Column className='w-4' field='matiereLibelle' header='Matiere'></Column>
        <Column className='w-1' header='CT' body={(rowData) => isOk(rowData, 'appelId')}></Column>
        <Column className='w-1' header='AN' body={(rowData) => isOk(rowData, 'ctId')}></Column>
        <Column className='w-1' header='Action' body={actionBody}></Column>
      </DataTable>
      <Paginator template={template} first={first} rows={rows} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChangeDef}></Paginator>
      <Dialog visible={openDetail} header='Détails de séance' className={'bg-grey-500'} style={{ width: '80%', height: '80%' }} onHide={hideDetail}>
        <div className='grid surface-50' style={{ width: '100%', height: '100%' }}>
          <div className='col-12 grid shadow-4 border-300 mt-3 p-2'>
            <div className='md:col-4 text-center lg:col-4 sm-col-12 text-4xl font-bold'>
              <div className='text-orange m-3' style={{ height: '20px' }}>
                {detail?.classeLibelle}
              </div>
              <div className='text-400 m-3' style={{ height: '20px' }}>
                {detail?.matiereLibelle}
              </div>
            </div>
            <div className='md:col-4 text-center lg:col-4 sm-col-12 text-4xl font-bold'>
              <div className='text-orange m-3' style={{ height: '20px' }}>
                {detail?.profNomPrenom}
              </div>
              <div className='text-400 m-3' style={{ height: '20px' }}>
                Enseignant
              </div>
            </div>
            <div className='md:col-4 text-center lg:col-4 sm-col-12 text-4xl font-bold'>
              <div className='text-orange m-3' style={{ height: '15px' }}>
                {detail.date ? getReadableDate(detail.date) : ''}
              </div>
              <div className='text-400 m-3' style={{ height: '15px' }}>
                {detail?.heureDebut} - {detail?.heureFin}
              </div>
            </div>
          </div>
          <div className='md:col-3 lg:col-3 sm-col-12 text-center shadow-4 border-300 m-4 text-4xl font-bold' style={{ height: '150px' }}>
            <div className='text-700'>Appel Numérique</div>
            <div className='m-1'>{isOk(detail, 'appelId')}</div>
            <div></div>
          </div>

          <div className='md:col-3 lg:col-3 sm-col-12 text-center shadow-4 border-300 m-4 text-4xl font-bold' style={{ height: '150px' }}>
            <div className='text-700'>Cahier de texte</div>
            <div className='m-1'>{isOk(detail, 'ctId')}</div>
            <div></div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProgressionStat, comparisonFn);