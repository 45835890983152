import { Badge } from "primereact/badge";
import { Chart } from "primereact/chart";
import { Knob } from "primereact/knob";
import CountUp from 'react-countup';
import React, { useEffect, useState } from "react";
import { DashBoardFondateurService } from "../../service/DashBoardFondateurService";
import {useHistory} from "react-router-dom";


import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

const items = [
  {
    href: '#',
    iconClass: 'bi bi-bank',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: '#',
    iconClass: 'bi bi-calendar-event',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: '#',
    iconClass: 'bi bi-percent',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: '#',
    iconClass: 'bi bi-cash-stack',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: '#',
    iconClass: 'bi bi-person-walking',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: '#',
    iconClass: 'bi bi-tags',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: 'investment-investment-plans.html',
    iconClass: 'bi bi-shield-check',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
  {
    href: '#',
    iconClass: 'bi bi-building-lock',
    text: 'Nous affichons vos meilleurs élèves ici!',
  },
];




const DashBoardFondateur = (props) => {
    const [dataBlock, setDataBlock] = useState({});
    const [barOptions, setBarOptions] = useState(null);
    const [pannee, setPannee] = useState(sessionStorage.getItem('AnneEncours'));
    const [pecole, setPecole] = useState(sessionStorage.getItem('EcoleID'));
    const profilHeader = props.profil;

    let yourDate = new Date();
    let history = useHistory();

    useEffect(() => {
        history.push("/home/professionnelle");
        console.log("/home/professionnelle refresh1")
    }, [history]);



    // console.log(props.infos);
    useEffect(() => {

        const dash = new DashBoardFondateurService();
        dash.getDatas(sessionStorage.getItem('EcoleID'), sessionStorage.getItem('AnneEncours')).then(resp => {
            setDataBlock(resp);
          //  console.log(resp)
        });
        const fetchData = async () => {
            try {
                console.log('init annee : ' + pannee + ' ecole : ' + pecole);
                setPannee();
                setPecole();

                const dash = new DashBoardFondateurService();
                const resp = await dash.getDatas(pecole, pannee);

                console.log('setted annee : ' + pannee + ' ecole : ' + pecole);
                setDataBlock(resp);
                console.log(resp);
            } catch (error) {
                console.error('Une erreur s\'est produite :', error);
            }
        };

        fetchData();

        applyLightTheme();

    }, [props.colorMode]);

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join('/') + ' à ' + time1.split('.')[0];
        } else
            return '';
    }

    const barDataMoyenne = {
        labels: ['Moyenne Générale'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };
    const barDataEvaluation = {
        labels: ['Evaluation des élèves'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [8]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [5]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [10]
            }
        ]
    };
    const barDataSeance = {
        labels: ['Séance de cours'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [2]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [1]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [5]
            }
        ]
    };
    const barDataProgression = {
        labels: ['Progression de cours'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };
    const barDataAppel = {
        labels: ['Fréquence appel numérique'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };
    const barDataPresence = {
        labels: ['Presence des élèves'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };

    const applyLightTheme = () => {
        const barOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };
        setBarOptions(barOptions);
    }

    const pieChartDataStatut = {
        labels: ['NAFF', 'AFF'],
        datasets: [
            {
                data: [dataBlock.nombreEleveNonAff, dataBlock.nombreEleveAff],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataGenre = {
        labels: ['NAFF', 'AFF'],
        datasets: [
            {
                data: [dataBlock.nombreEleveNouvNonAffFilles + dataBlock.nombreEleveNouvNonAffGarcons, dataBlock.nombreEleveNouvAffFilles + dataBlock.nombreEleveNouvAffGarcons],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataAnciennete = {
        labels: ['NAFF', 'AFF'],
        datasets: [
            {
                data: [dataBlock.nombreEleveAncNonAffFilles + dataBlock.nombreEleveAncNonAffGarcons, dataBlock.nombreEleveAncAffFilles + dataBlock.nombreEleveAncAffGarcons],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataDisponible = {
        labels: ['SNOP', 'SOP'],
        datasets: [
            {
                data: [dataBlock.sallesNonOperationnelles, dataBlock.sallesOperationnelles],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataUtilise = {
        labels: ['SNU ', 'SU '],
        datasets: [
            {
                data: [dataBlock.sallesNonUtilisees, dataBlock.sallesUtilisees],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };

    const pieChartDataPersEns = {
        labels: ['Femmes', 'Hommes'],
        datasets: [
            {
                data: [dataBlock.personnelEnseignantFeminin, dataBlock.personnelEnseignantMasculin],
                backgroundColor: [
                    "#FF8C00",
                    "#1E90FF"
                ],
                hoverBackgroundColor: [
                    "#FFA500",
                    "#87CEFA"
                ]
            }
        ]
    };
    const pieChartDataEduc = {
        labels: ['Femmes', 'Hommes'],
        datasets: [
            {
                data: [dataBlock.personnelEducateurFeminin, dataBlock.personnelEducateurMasculin],
                backgroundColor: [
                    "#FF8C00",
                    "#1E90FF"
                ],
                hoverBackgroundColor: [
                    "#FFA500",
                    "#87CEFA"
                ]
            }
        ]
    };
    const pieChartDataAdm = {
        labels: ['Femmes', 'Hommes'],
        datasets: [
            {
                data: [dataBlock.personnelAdmFeminin, dataBlock.personnelAdmMasculin],
                backgroundColor: [
                    "#FF8C00",
                    "#1E90FF"
                ],
                hoverBackgroundColor: [
                    "#FFA500",
                    "#87CEFA"
                ]
            }
        ]
    };

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    return (
        <>

        <div className="row align-items-center">
            <div className="col-xl-8 col-lg-6 col-xs-12 mb-4">
                <h3 className="fw-normal mb-0 text-secondary">Bienvenue</h3>
                <h1 className="m-0">Tableau de bord {profilHeader}</h1>
            </div>
            <div className="col-xl-4 col-lg-6 col-xs-12">
                <div className="row">
                    {/* <div className="col-4 col-sm-4 col-lg-3 col-xl-2 mb-4">
                        <div className="card adminuiux-card">
                        <div className="card-body">
                            <p className="text-secondary small mb-2">Total Elève</p>
                            <h4 className="mb-3"><CountUp preserveValue={true} start={0} end={dataBlock.nombreTotalEleve} delay={1} /></h4>
                            <span className="badge badge-light text-bg-success">
                            <i className="me-1 bi bi-arrow-up-short" />
                            28.50%
                            </span>
                        </div>
                        </div>
                    </div> */}
                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4">
                        <div className="card adminuiux-card">
                        <div className="card-body">
                            <p className="text-secondary small mb-2">Total élève Garçon</p>
                            <h4 className="mb-3"><CountUp preserveValue={true} start={0} end={dataBlock.nombreTotalEleveGarcons == 0 ? '0 ' : dataBlock.nombreTotalEleveGarcons} delay={1} /></h4>
                            <span className="badge badge-light text-bg-success">
                            <i className="me-1 bi bi-arrow-up-short" />

                            </span>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4">
                        <div className="card adminuiux-card">
                        <div className="card-body">
                            <p className="text-secondary small mb-2">Total élève Fille</p>
                            <h4 className="mb-3"><CountUp preserveValue={true} start={0} end={dataBlock.nombreTotalEleveFilles == 0 ? '0 ' : dataBlock.nombreTotalEleveFilles} delay={1} /></h4>
                            <span className="badge badge-light text-bg-danger">
                            <i className="me-1 bi bi-arrow-down-short" />

                            </span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row align-items-center">
            <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
                <div className="card adminuiux-card position-relative overflow-hidden bg-theme-1 h-100">
                <div
                    className="position-absolute top-0 start-0 h-100 w-100 z-index-0 coverimg opacity-50"
                    style={{
                    backgroundImage: 'url("assets/img/modern-ai-image/flamingo-4.jpg")'
                    }}
                >
                    <img
                    src="assets/img/modern-ai-image/flamingo-4.jpg"
                    alt=""
                    style={{ display: "none" }}
                    />
                </div>
                <div className="card-body z-index-1">
                    <div className="row align-items-center justify-content-center h-100 py-4">
                    <div className="col-11">
                        <h2 className="fw-normal">
                        Nombre total des élèves
                        </h2>
                        <h1 className="mb-3"><CountUp preserveValue={true} start={0} end={dataBlock.nombreTotalEleve} delay={1} /></h1>
                        <p></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-xs-12 col-lg-6 col-xl-8 mb-4">
                <div className="row">
                <div className="col-xs-12 col-lg-6 mb-4">
                    <div className="card adminuiux-card">
                    <div className="card-body">



                        <div className="row gx-3">
                        <div className="col">
                            <h4>Votre avis compte</h4>
                            <p className="text-secondary">
                            Dites nous ce que vous voulez voir afficher ici &amp;
                                Nous voulons construire avec vous!
                            </p>
                            <button className="btn btn-sm btn-outline-theme my-1">
                            Merci !
                            </button>
                        </div>
                        <div className="col-auto">
                            <div className="avatar avatar-80 rounded bg-theme-1-subtle text-theme-1">
                            <i className="bi bi-send h1" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-xs-12 col-lg-6 mb-4">
                    <div className="card adminuiux-card border border-theme-1">
                    <div className="card-body">
                        <div className="row gx-3">
                        <div className="col-auto text-center">
                            <div
                            className="avatar avatar-140 rounded coverimg"
                            style={{
                                backgroundImage:
                                'url("assets/img/modern-ai-image/taxoi-1.jpg")'
                            }}
                            >
                            <img
                                src="assets/img/modern-ai-image/taxoi-1.jpg"
                                alt=""
                                style={{ display: "none" }}
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="row align-items-center mb-3">
                            <div className="col">
                                <p>
                                <span className="badge badge-light text-bg-theme-1">

                                </span>
                                </p>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-sm btn-outline-theme">

                                </button>
                            </div>
                            </div>
                            <h4>Exprimez vos attentes</h4>
                            <p className="mb-1 small">
                                Votre opinion compte, et nous construisons l'avenir de l'éducation avec vous !
                            </p>
                            <p className="small">
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>




        <div className="row">

            <div className="col-xs-12 mb-4">
                <div className="row align-items-center">
              {/*  <div className="col">
                    <h6 className="mb-0">Updates:</h6>
                    <p className="small text-secondary">
                    Today <span className="text-danger">Live</span>
                    </p>
                </div>*/}
                    {/*  <div className="col-xs-12 col-sm-10">
                  <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">

                        <Splide
                            options={{
                            perPage: 7,
                            gap: '1rem',
                            rewind: true,
                            pagination: false,
                            breakpoints: {
                                768: {
                                perPage: 1,
                                },
                                1024: {
                                perPage: 2,
                                },
                            },
                            }}
                        >
                            {items.map((item, index) => (
                            <SplideSlide key={index}>
                                <div className="swiper-slide width-200 swiper-slide-active" style={{ marginRight: 16 }}>
                                    <h6 className="mb-0 text-success">24,806.00</h6>
                                    <p className="small">
                                        <span className="text-secondary">GIFTS NIFTYS:</span>{" "}
                                        <span className="text-success">
                                        <i className="bi bi-caret-up-fill" /> 1.40%
                                        </span>
                                    </p>
                                </div>
                            </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                </div> */}
                <div className="col-auto">
                    <button className="btn btn-sm btn-square btn-link">
                    <i className="bi bi-arrow-clockwise" />
                    </button>
                </div>
                </div>
            </div>


            <div className="col-xs-12 mb-4">
                <h5>Le Personnel de votre établissement </h5>
            </div>

            <div className="col-xs-12 col-lg-12 col-xl-12 mb-4">
                <div className="col-xs-12">
                    <div className="card adminuiux-card mb-3 py-0">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-9 col-xxl mb-xxl-0">
                                <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 rounded coverimg" style={{ backgroundImage: 'url("assets/layout/images/logo-gain.png")' }}>
                                        <img src="assets/layout/images/logo-gain.png" alt="" style={{ display: "nones" }}/>
                                    </div>
                                </div>
                                <div className="col">
                                    <h5>Personnel administratif</h5>
                                    <span className="badge badge-light text-bg-theme-1"> {dataBlock.personnelAdm == 0 ? '0 ' : dataBlock.personnelAdm} au Total </span>{" "}
                                    <span className="badge badge-light text-bg-info mx-1"> Homme </span>{" "}
                                    <span className="badge badge-light text-bg-warning"> Femme </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-xxl-auto">
                                  <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '130px' }} />
                            </div>
                            <div className="col-xs-12 col-md-9 col-xxl-4">
                                <div className="card p-0 mb-0">
                                <div className="card-body card-border">
                                    <div className="row align-items-center justify-content-between">
                                    <div className="col-auto text-start">
                                        <h5 className="mb-1">
                                        {dataBlock.personnelAdmMasculin == 0 ? '0 ' : dataBlock.personnelAdmMasculin}
                                        {/* <small> <span className="badge badge-sm badge-light text-bg-success mx-1 fw-normal"> Tax Free </span> </small> */}
                                        </h5>
                                        <p className="text-secondary small">Homme</p>
                                    </div>
                                    <div className="col-auto">
                                        <i className="bi bi-plus-lg" />
                                    </div>
                                    <div className="col-auto text-end">
                                        <h5>{dataBlock.personnelAdmFeminin == 0 ? '0 ' : dataBlock.personnelAdmFeminin}</h5>
                                        <p className="text-secondary small">Femme</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-auto my-3">
                                {/*<button className="btn btn-outline-theme">Get Details</button>*/}
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xs-12">
                    <div className="card adminuiux-card mb-3 py-0">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-9 col-xxl mb-xxl-0">
                                <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 rounded coverimg" style={{ backgroundImage: 'url("assets/layout/images/logo-gain.png")' }}>
                                        <img src="assets/layout/images/logo-gain.png" alt="" style={{ display: "nones" }}/>
                                    </div>
                                </div>
                                <div className="col">
                                    <h5>Personnel Educateur</h5>
                                    <span className="badge badge-light text-bg-theme-1"> {dataBlock.personnelEducateur == 0 ? '0 ' : dataBlock.personnelEducateur} au Total </span>{" "}
                                    <span className="badge badge-light text-bg-info mx-1"> Homme </span>{" "}
                                    <span className="badge badge-light text-bg-warning"> Femme </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-xxl-auto">
                                  <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '130px' }} />
                            </div>
                            <div className="col-xs-12 col-md-9 col-xxl-4">
                                <div className="card p-0 mb-0">
                                <div className="card-body card-border">
                                    <div className="row align-items-center justify-content-between">
                                    <div className="col-auto text-start">
                                        <h5 className="mb-1">
                                        {dataBlock.personnelEducateurMasculin == 0 ? '0 ' : dataBlock.personnelEducateurMasculin}
                                        {/* <small> <span className="badge badge-sm badge-light text-bg-success mx-1 fw-normal"> Tax Free </span> </small> */}
                                        </h5>
                                        <p className="text-secondary small">Homme</p>
                                    </div>
                                    <div className="col-auto">
                                        <i className="bi bi-plus-lg" />
                                    </div>
                                    <div className="col-auto text-end">
                                        <h5>{dataBlock.personnelEducateurFeminin == 0 ? '0 ' : dataBlock.personnelEducateurFeminin}</h5>
                                        <p className="text-secondary small">Femme</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-auto my-3">

                                {/*<button className="btn btn-outline-theme">Get Details</button>*/}
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xs-12">
                    <div className="card adminuiux-card mb-3 py-0">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-9 col-xxl mb-xxl-0">
                                <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 rounded coverimg" style={{ backgroundImage: 'url("assets/layout/images/logo-gain.png")' }}>
                                        <img src="assets/layout/images/logo-gain.png" alt="" style={{ display: "nones" }}/>
                                    </div>
                                </div>
                                <div className="col">
                                    <h5>Personnel enseignant</h5>
                                    <span className="badge badge-light text-bg-theme-1"> {dataBlock.personnelEnseignant == 0 ? '0 ' : dataBlock.personnelEnseignant} au Total </span>{" "}
                                    <span className="badge badge-light text-bg-info mx-1"> Homme </span>{" "}
                                    <span className="badge badge-light text-bg-warning"> Femme </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-xxl-auto">
                                  <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '130px' }} />
                            </div>
                            <div className="col-xs-12 col-md-9 col-xxl-4">
                                <div className="card p-0 mb-0">
                                <div className="card-body card-border">
                                    <div className="row align-items-center justify-content-between">
                                    <div className="col-auto text-start">
                                        <h5 className="mb-1">
                                        {dataBlock.personnelEnseignantMasculin == 0 ? '0 ' : dataBlock.personnelEnseignantMasculin}
                                        {/* <small> <span className="badge badge-sm badge-light text-bg-success mx-1 fw-normal"> Tax Free </span> </small> */}
                                        </h5>
                                        <p className="text-secondary small">Homme</p>
                                    </div>
                                    <div className="col-auto">
                                        <i className="bi bi-plus-lg" />
                                    </div>
                                    <div className="col-auto text-end">
                                        <h5>{dataBlock.personnelEnseignantFeminin == 0 ? '0 ' : dataBlock.personnelEnseignantFeminin}</h5>
                                        <p className="text-secondary small">Femme</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-auto my-3">

                                {/*<button className="btn btn-outline-theme">Get Details</button>*/}
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xs-12">
                    <div className="card adminuiux-card mb-3 py-0">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-9 col-xxl mb-xxl-0">
                                <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 rounded coverimg" style={{ backgroundImage: 'url("assets/layout/images/logo-gain.png")' }}>
                                        <img src="assets/layout/images/logo-gain.png" alt="" style={{ display: "nones" }}/>
                                    </div>
                                </div>
                                <div className="col">
                                    <h5>Enseignant Permanent</h5>
                                    <span className="badge badge-light text-bg-theme-1"> {dataBlock.enseignantPermanent == 0 ? '0 ' : dataBlock.enseignantPermanent} au Total </span>{" "}
                                    <span className="badge badge-light text-bg-info mx-1"> Homme </span>{" "}
                                    <span className="badge badge-light text-bg-warning"> Femme </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-xxl-auto">
                                  <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '130px' }} />
                            </div>
                            <div className="col-xs-12 col-md-9 col-xxl-4">
                                <div className="card p-0 mb-0">
                                <div className="card-body card-border">
                                    <div className="row align-items-center justify-content-between">
                                    <div className="col-auto text-start">
                                        <h5 className="mb-1">
                                       {dataBlock.enseignantPermanentMasculin == 0 ? '0 ' : dataBlock.enseignantPermanentMasculin}
                                        {/* <small> <span className="badge badge-sm badge-light text-bg-success mx-1 fw-normal"> Tax Free </span> </small> */}
                                        </h5>
                                        <p className="text-secondary small">Homme</p>
                                    </div>
                                    <div className="col-auto">
                                        <i className="bi bi-plus-lg" />
                                    </div>
                                    <div className="col-auto text-end">
                                        <h5>{dataBlock.enseignantPermanentFeminin == 0 ? '0 ' : dataBlock.enseignantPermanentFeminin}</h5>
                                        <p className="text-secondary small">Femme</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-auto my-3">

                                {/*<button className="btn btn-outline-theme">Get Details</button>*/}
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xs-12">
                    <div className="card adminuiux-card mb-3 py-0">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-9 col-xxl mb-xxl-0">
                                <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 rounded coverimg" style={{ backgroundImage: 'url("assets/layout/images/logo-gain.png")' }}>
                                        <img src="assets/layout/images/logo-gain.png" alt="" style={{ display: "nones" }}/>
                                    </div>
                                </div>
                                <div className="col">
                                    <h5>Enseignant vacataire</h5>
                                    <span className="badge badge-light text-bg-theme-1"> {dataBlock.enseignantVacataire == 0 ? '0 ' : dataBlock.enseignantVacataire} au Total </span>{" "}
                                    <span className="badge badge-light text-bg-info mx-1"> Homme </span>{" "}
                                    <span className="badge badge-light text-bg-warning"> Femme </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-xxl-auto">
                                  <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '130px' }} />
                            </div>
                            <div className="col-xs-12 col-md-9 col-xxl-4">
                                <div className="card p-0 mb-0">
                                <div className="card-body card-border">
                                    <div className="row align-items-center justify-content-between">
                                    <div className="col-auto text-start">
                                        <h5 className="mb-1">
                                       {dataBlock.enseignantVacataireMasculin == 0 ? '0 ' : dataBlock.enseignantVacataireMasculin}
                                        {/* <small> <span className="badge badge-sm badge-light text-bg-success mx-1 fw-normal"> Tax Free </span> </small> */}
                                        </h5>
                                        <p className="text-secondary small">Homme</p>
                                    </div>
                                    <div className="col-auto">
                                        <i className="bi bi-plus-lg" />
                                    </div>
                                    <div className="col-auto text-end">
                                        <h5>{dataBlock.enseignantVacataireFeminin == 0 ? '0 ' : dataBlock.enseignantVacataireFeminin}</h5>
                                        <p className="text-secondary small">Femme</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-auto my-3">

                                {/*<button className="btn btn-outline-theme">Get Details</button>*/}
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>









            <div className="col-xs-12">
                <Splide
                    options={{
                    perPage: 7,
                    gap: '1rem',
                    rewind: true,
                    pagination: false,
                    breakpoints: {
                        768: {
                        perPage: 1,
                        },
                        1024: {
                        perPage: 2,
                        },
                    },
                    }}
                >
                    {items.map((item, index) => (
                    <SplideSlide key={index}>
                        <div className="">
                        <a href={item.href} className="card adminuiux-card style-none text-center h-100">
                            <div className="card-body">
                            <i class="pi pi-user fs-4 avatar avatar-50 bg-theme-2-subtle text-theme-2 rounded"></i>
                            <p className="text-secondary small m-0">{item.text}</p>
                            </div>
                        </a>
                        </div>
                    </SplideSlide>
                    ))}
                </Splide>
            </div>




            <div className="col-xs-12 col-xxl-8">
                <div className="row">
                    <div className="col-xs-12 col-lg-6 mb-4">
                        <div className="card adminuiux-card">
                        <div className="card-body">
                            <div className="row gx-3 mb-3">
                            <div className="col-auto">
                                <i className="pi pi-user fs-4 avatar avatar-50 bg-success text-white rounded" />
                            </div>
                            <div className="col edmond">
                                <h4 className="mb-0"><CountUp preserveValue={true} start={0} end={dataBlock.nombreTotalEleveGarcons == 0 ? '0 ' : dataBlock.nombreTotalEleveGarcons} delay={1} /></h4>
                                <p className="small opacity-75">Total Garçon</p>
                            </div>
                            </div>
                            <div className="row mb-2">
                            <div className="col-auto"></div>
                            <div className="col" />
                            <div className="col-auto"></div>
                            </div>
                            <div className="progress height-10 mb-2"
                            role="progressbar"
                            aria-label="Basic example" aria-valuenow={0}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            >
                            <div className="progress-bar progress-bar-striped bg-success"
                                style={{ width: "40%" }}
                            />
                            </div>
                            <div className="row small text-secondary">
                            <div className="col-auto">10%</div>
                            <div className="col" />
                            <div className="col-auto">90%</div>
                            </div>
                        </div>

                        <div className="card adminuiux-card bg-theme-1-subtle mb-3 d-flex flex-row">
                            <div className="row">
                                <div className="col-lg-4">
                                <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                    <div className="swiper-wrapper">
                                        <div
                                        className="swiper-slide width-200 swiper-slide-active"
                                        style={{ marginRight: 16 }}
                                        >
                                        <h6 className="mb-0 text-success">{dataBlock.nombreEleveAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAffGarcons} </h6>
                                        <p className="small">
                                            <span className="text-secondary">Affectés</span>{" "}
                                            <span className="text-success">
                                            <i className="bi bi-caret-up-fill" /> 1.40%
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                    <div className="swiper-wrapper">
                                        <div
                                        className="swiper-slide width-200 swiper-slide-active"
                                        style={{ marginRight: 16 }}
                                        >
                                        <h6 className="mb-0 text-success">{dataBlock.nombreEleveNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNonAffGarcons}</h6>
                                        <p className="small">
                                            <span className="text-secondary">Non affectés</span>{" "}
                                            <span className="text-success">
                                            <i className="bi bi-caret-up-fill" /> 1.40%
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col">
                                <h4  className="mb-0 ">Ancien</h4>
                                <div className="row">

                                    {/* <div className="col-lg-4">
                                        <div className="card adminuiux-card bg-theme-1-subtle mb-3 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">24,806.00</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Total ancien élèves</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-12">
                                        <div className="card adminuiux-card  mb-0 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-theme-2">{dataBlock.nombreEleveAncAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAncAffGarcons}</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève Affectés</span>{" "}
                                                                <span className="text-theme-2">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-theme-2">{dataBlock.nombreEleveAncNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAncNonAffGarcons} </h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève non Affectés</span>{" "}
                                                                <span className="text-theme-2">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="avatar avatar-80 rounded bg-theme-2-subtle text-theme-2">
                                {/* <i className="bi bi-send h1" /> */}
                                <h3>{dataBlock.nombreEleveAnc == 0 ? '0 ' : dataBlock.nombreEleveAnc}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-3">
                            <div className="col">
                                <h4  className="mb-0">Nouveau</h4>
                                <div className="row">

                                    {/* <div className="col-lg-4">
                                        <div className="card adminuiux-card bg-theme-1-subtle mb-3 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">24,806.00</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Total ancien élèves</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-12">
                                        <div className="card adminuiux-card  mb-0 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">{dataBlock.nombreEleveNouvAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNouvAffGarcons}</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève Affectés</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">{dataBlock.nombreEleveNouvNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNouvNonAffGarcons}</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève non Affectés</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="avatar avatar-80 rounded bg-theme-1-subtle text-theme-1">
                                {/* <i className="bi bi-send h1" /> */}
                                <h3>{dataBlock.nombreEleveNouv == 0 ? '0 ' : dataBlock.nombreEleveNouv}</h3>
                                </div>
                            </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-xs-12 col-lg-6 mb-4">
                        <div className="card adminuiux-card">
                            <div className="card-body">
                                <div className="row gx-3 mb-3">
                                <div className="col-auto">
                                    <i className="pi pi-user fs-4 avatar avatar-50 bg-theme-1 text-white rounded" />
                                </div>
                                <div className="col">
                                    <h4 className="mb-0"><CountUp preserveValue={true} start={0} end={dataBlock.nombreTotalEleveFilles == 0 ? '0 ' : dataBlock.nombreTotalEleveFilles} /></h4>
                                    <p className="small opacity-75">Total Fille</p>
                                </div>
                                </div>
                                <div className="row mb-2">
                                <div className="col-auto"></div>
                                <div className="col" />
                                <div className="col-auto"></div>
                                </div>
                                <div
                                className="progress height-10 mb-2"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow={0}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                >
                                <div
                                    className="progress-bar progress-bar-striped bg-theme-1"
                                    style={{ width: "60%" }}
                                />
                                </div>
                                <div className="row small text-secondary">
                                    <div className="col-auto"></div>
                                    <div className="col" />
                                    <div className="col-auto"></div>
                                </div>

                                <div className="card adminuiux-card bg-theme-1-subtle mb-3 d-flex flex-row">
                            <div className="row">
                                <div className="col-lg-4">
                                <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                    <div className="swiper-wrapper">
                                        <div
                                        className="swiper-slide width-200 swiper-slide-active"
                                        style={{ marginRight: 16 }}
                                        >
                                        <h6 className="mb-0 text-success">{dataBlock.nombreEleveAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAffFilles} </h6>
                                        <p className="small">
                                            <span className="text-secondary">Affectés</span>{" "}
                                            <span className="text-success">
                                            <i className="bi bi-caret-up-fill" /> 1.40%
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                    <div className="swiper-wrapper">
                                        <div
                                        className="swiper-slide width-200 swiper-slide-active"
                                        style={{ marginRight: 16 }}
                                        >
                                        <h6 className="mb-0 text-success">{dataBlock.nombreEleveNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNonAffFilles}</h6>
                                        <p className="small">
                                            <span className="text-secondary">Non affectés</span>{" "}
                                            <span className="text-success">
                                            <i className="bi bi-caret-up-fill" /> 1.40%
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col">
                                <h4  className="mb-0 ">Ancienne</h4>
                                <div className="row">

                                    {/* <div className="col-lg-4">
                                        <div className="card adminuiux-card bg-theme-1-subtle mb-3 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">24,806.00</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Total ancien élèves</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-12">
                                        <div className="card adminuiux-card  mb-0 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-theme-2">{dataBlock.nombreEleveAncAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAncAffFilles}</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève Affectés</span>{" "}
                                                                <span className="text-theme-2">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-theme-2">{dataBlock.nombreEleveAncNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAncNonAffFilles} </h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève non Affectés</span>{" "}
                                                                <span className="text-theme-2">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="avatar avatar-80 rounded bg-theme-2-subtle text-theme-2">
                                {/* <i className="bi bi-send h1" /> */}
                                <h3>{dataBlock.nombreEleveAnc == 0 ? '0 ' : dataBlock.nombreEleveAnc}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-3">
                            <div className="col">
                                <h4  className="mb-0">Nouvelle</h4>
                                <div className="row">

                                    {/* <div className="col-lg-4">
                                        <div className="card adminuiux-card bg-theme-1-subtle mb-3 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">24,806.00</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Total ancien élèves</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-12">
                                        <div className="card adminuiux-card  mb-0 d-flex flex-row">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">{dataBlock.nombreEleveNouvAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNouvAffFilles}</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève Affectés</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="swiper swipernav swiper-initialized swiper-horizontal swiper-backface-hidden">
                                                        <div className="swiper-wrapper">
                                                            <div
                                                            className="swiper-slide width-200 swiper-slide-active"
                                                            style={{ marginRight: 16 }}
                                                            >
                                                            <h6 className="mb-0 text-success">{dataBlock.nombreEleveNouvNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNouvNonAffFilles}</h6>
                                                            <p className="small">
                                                                <span className="text-secondary">Elève non Affectés</span>{" "}
                                                                <span className="text-success">
                                                                <i className="bi bi-caret-up-fill" /> 1.40%
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="avatar avatar-80 rounded bg-theme-1-subtle text-theme-1">
                                {/* <i className="bi bi-send h1" /> */}
                                <h3>{dataBlock.nombreEleveNouv == 0 ? '0 ' : dataBlock.nombreEleveNouv}</h3>
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
            <div className="col-xs-12 col-xxl-4 mb-4">
          {/*      <div className="card adminuiux-card bg-theme-1">
                <div className="card-body">
                    <h2 className="mb-3 fw-medium">Adminuiux Innovation and tech Fund</h2>
                    <h4 className="h4 mb-1">$ 15.52</h4>
                    <p className="opacity-75 mb-3">Current Nav (Today)</p>
                    <div className="row">
                    <div className="col-6 mb-3">
                        <h5 className="mb-1">21</h5>
                        <p className="small opacity-75">
                        <i className="bi bi-speedometer2 me-1" /> Risk
                        </p>
                    </div>
                    <div className="col-6 mb-3">
                        <h5 className="mb-1">15.25%</h5>
                        <p className="small opacity-75">
                        <i className="bi bi-bar-chart-line me-1" /> CAGR
                        </p>
                    </div>
                    <div className="col-6 mb-3">
                        <h5 className="mb-1">0.5%</h5>
                        <p className="small opacity-75">
                        <i className="bi bi-exclamation-triangle me-1" /> Exit Load
                        </p>
                    </div>
                    <div className="col-6 mb-3">
                        <h5 className="mb-1">0.25%</h5>
                        <p className="small opacity-75">
                        <i className="bi bi-cash-stack me-1" /> Expense Ratio
                        </p>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col">
                        <button className="btn btn-light me-2">Buy</button>{" "}
                        <button className="btn btn-light me-2">SIP</button>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link text-white me-2">Details</button>
                    </div>
                    </div>
                </div>
                </div>*/}
            </div>
        </div>

        {/* Ancier version */}

            <div className="grid crud-demo d-none">
                        <div className="col-12">
                            <div className="card bg-green-600">
                                <p className="font-bold text-2xl text-white"> Dashboard {profilHeader}</p>
                                <p className="ml-3 font-bold text-lg font-italic text-white"> Bienvenue</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card grid">
                                <div className="col-3 text-center">
                                    <p className="text-lg font-bold">Ce {formatDate(yourDate.toISOString())}</p>
                                    <div className="p-field p-col-12 p-md-4">

                                        <Knob value={0} readOnly />
                                    </div>
                                    <p className="font-italic font-bold text-500">La note de votre établissement n'est pas encore disponible</p>
                                </div>
                                <div className="col-9 card-container flex align-items-center surface-100">
                                    <div className="mx-2 hidden">
                                        <Chart type="bar" data={barDataSeance} options={barOptions} height={'200px'} width={'150px'} />
                                    </div>
                                    <div className="mx-2 hidden">
                                        <Chart type="bar" data={barDataProgression} options={barOptions} height={'200px'} width={'150px'} />
                                    </div>
                                    <div className="mx-2 hidden">
                                        <Chart type="bar" data={barDataAppel} options={barOptions} height={'200px'} width={'150px'} />
                                    </div>
                                    <div className="mx-2 hidden">
                                        <Chart type="bar" data={barDataPresence} options={barOptions} height={'200px'} width={'150px'} />
                                    </div>
                                    <div className="mx-2 hidden">
                                        <Chart type="bar" data={barDataEvaluation} options={barOptions} height={'200px'} width={'150px'} />
                                    </div>
                                    <div className="mx-2 hidden">
                                        <Chart type="bar" data={barDataMoyenne} options={barOptions} height={'200px'} width={'150px'} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="font-bold font-italic surface-300">APPRECIATION non disponible</p>
                                </div>
                                <div className="col-12 grid border-400 border-bottom-1">

                                    <div className="grid md:col-6 lg:col-6 sm:col-12 font-bold">
                                        <div className="col-12 border-400">
                                            <p className="font-bold">Votre effectif ce jour {formatDate(yourDate.toISOString())}</p>
                                        </div>
                                        <div className="col-4 surface-300">
                                            <p><CountUp className="p-badge p-badge-lg" style={{backgroundColor:'#000'}} preserveValue={true} start={0} end={dataBlock.nombreTotalEleve} delay={1} /> <span className="mx-3 text-xl">Elèves</span></p>                        </div>
                                        <div className="col-4 relative surface-300 ">
                                            <p className="text-primary">G : <Badge className="absolute right-0 mr-2 " value={dataBlock.nombreTotalEleveGarcons == 0 ? '0 ' : dataBlock.nombreTotalEleveGarcons} /> </p>
                                        </div>
                                        <div className="col-4 relative surface-300">
                                            <p style={{color:'#FF647F'}}> <span >F</span> : <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2 " value={dataBlock.nombreTotalEleveFilles == 0 ? '0 ' : dataBlock.nombreTotalEleveFilles} /> </p>
                                        </div>
                                        <div className="col-4 relative border-400 border-bottom-1">
                                            <p>AFF: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAff == 0 ? '0 ' : dataBlock.nombreEleveAff} /></p>
                                            <p>NAFF: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNonAff == 0 ? '0 ' : dataBlock.nombreEleveNonAff} /></p>
                                        </div>
                                        <div className="col-4 relative border-400 border-bottom-1">
                                            <p className="text-primary">G: <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAffGarcons} /></p>
                                            <p className="text-primary">G: <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNonAffGarcons} /></p>
                                        </div>
                                        <div className="col-4 relative border-400 border-bottom-1">
                                            <p style={{color:'#FF647F'}}>F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAffFilles} /></p>
                                            <p style={{color:'#FF647F'}}>F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNonAffFilles} /></p>
                                        </div>
                                        <div className="grid col-12 ml-0 px-0">
                                            <div className="col-4 pt-5 relative">
                                                <p className="">NOUVEAU: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouv == 0 ? '0 ' : dataBlock.nombreEleveNouv} /></p>
                                                <p className="pt-5">ANCIEN: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAnc == 0 ? '0 ' : dataBlock.nombreEleveAnc} /></p>
                                            </div>

                                            <div className="col-4 relative">
                                                <p className="text-primary">AFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNouvAffGarcons} /></p>
                                                <p className="border-bottom-1 text-primary border-400">NAFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNouvNonAffGarcons} /></p>
                                                <p className="text-primary">AFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAncAffGarcons} /></p>
                                                <p className="text-primary">NAFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAncNonAffGarcons} /></p>
                                            </div>
                                            <div className="col-4 relative pl-2">
                                                <p style={{color:'#FF647F'}}>AFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNouvAffFilles} /></p>
                                                <p style={{color:'#FF647F'}} className="border-bottom-1 border-400">NAFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNouvNonAffFilles} /></p>
                                                <p style={{color:'#FF647F'}}>AFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAncAffFilles} /></p>
                                                <p style={{color:'#FF647F'}}>NAFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAncNonAffFilles} /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:col-6 lg:col-6 sm:col-6">
                                        <div className="pt-6 card-container flex align-items-center">
                                            <div className="md:col-4 lg:col-4 sm:col-4">
                                                <Chart type="pie" data={pieChartDataStatut} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                            </div>
                                            <div className="md:col-6 lg:col-4 sm:col-4">
                                                <Chart type="pie" data={pieChartDataGenre} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                            </div>
                                            <div className="md:col-6 lg:col-4 sm:col-4">
                                                <Chart type="pie" data={pieChartDataAnciennete} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 grid border-bottom-1 border-400">

                                    <div className="grid md:col-6 lg:col-6 sm:col-12 pl-0 font-bold">
                                        <div className="col-12 surface-300">
                                            <p>VOTRE EFFECTIF MOYEN PAR CLASSE: <Badge className="text-base mx-1" value={dataBlock.effectifMoyenClasse == 0 ? '0 ' : dataBlock.effectifMoyenClasse} /></p>
                                        </div>
                                        <div className="col-6 relative"><p>Nombre de classes: <Badge className="absolute right-0 mr-2" value={dataBlock.nombreClasses == 0 ? '0 ' : dataBlock.nombreClasses} /></p></div>
                                        <div className="col-6 relative"><p>Salles disponibles : <Badge className="absolute right-0 mr-2" value={dataBlock.sallesDispo == 0 ? '0 ' : dataBlock.sallesDispo} /></p></div>

                                        <div className="col-6 relative"><p>Salles opérationnelles: <Badge className="absolute right-0 mr-2" value={dataBlock.sallesOperationnelles == 0 ? '0 ' : dataBlock.sallesOperationnelles} /></p></div>
                                        <div className="col-6 relative"><p>Salles non opérationnelles : <Badge className="absolute right-0 mr-2" value={dataBlock.sallesNonOperationnelles == 0 ? '0 ' : dataBlock.sallesNonOperationnelles} /></p></div>

                                        <div className="col-6 relative"><p>Salles utilisées: <Badge className="absolute right-0 mr-2" value={dataBlock.sallesUtilisees == 0 ? '0 ' : dataBlock.sallesUtilisees} /></p></div>
                                        <div className="col-6 relative"><p>Salles non utilisées : <Badge className="absolute right-0 mr-2" value={dataBlock.sallesNonUtilisees == 0 ? '0 ' : dataBlock.sallesNonUtilisees} /></p></div>
                                    </div>


                                    <div className="md:col-6 lg:col-6 sm:col-12">
                                        <div className="card-container flex align-items-center">
                                            <Chart type="pie" data={pieChartDataDisponible} options={lightOptions} style={{ position: 'relative', width: '170px' }} />

                                            <Chart type="pie" data={pieChartDataUtilise} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="grid md:col-6 lg:col-6 sm:col-12 font-bold">
                                    <div className="col-12">
                                        Le Personnel de votre établissement : <Badge style={{backgroundColor:'#000'}} value={dataBlock.nombrePersonnel == 0 ? '0 ' : dataBlock.nombrePersonnel} />
                                    </div>
                                    <div className="col-5 relative">Personnel administratif: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.personnelAdm == 0 ? '0 ' : dataBlock.personnelAdm} /></div>
                                    <div className="col-3 relative" style={{color:'#FF647F'}}>
                                        F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.personnelAdmFeminin == 0 ? '0 ' : dataBlock.personnelAdmFeminin} />
                                    </div>
                                    <div className="col-4 relative text-primary">
                                        G: <Badge className="absolute right-0 mr-2" value={dataBlock.personnelAdmMasculin == 0 ? '0 ' : dataBlock.personnelAdmMasculin} />
                                    </div>

                                    <div className="col-5 relative">Personnel Educateur: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.personnelEducateur == 0 ? '0 ' : dataBlock.personnelEducateur} /></div>
                                    <div className="col-3 relative" style={{color:'#FF647F'}}>
                                        F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.personnelEducateurFeminin == 0 ? '0 ' : dataBlock.personnelEducateurFeminin} />
                                    </div>
                                    <div className="col-4 relative text-primary">
                                        G: <Badge className="absolute right-0 mr-2" value={dataBlock.personnelEducateurMasculin == 0 ? '0 ' : dataBlock.personnelEducateurMasculin} />
                                    </div>

                                    <div className="col-5 relative">Personnel enseignant: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.personnelEnseignant == 0 ? '0 ' : dataBlock.personnelEnseignant} /></div>
                                    <div className="col-3 relative" style={{color:'#FF647F'}}>
                                        F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.personnelEnseignantFeminin == 0 ? '0 ' : dataBlock.personnelEnseignantFeminin} />
                                    </div>
                                    <div className="col-4 relative text-primary">
                                        G: <Badge className="absolute right-0 mr-2" value={dataBlock.personnelEnseignantMasculin == 0 ? '0 ' : dataBlock.personnelEnseignantMasculin} />
                                    </div>

                                    <div className="col-5 relative">Enseignant Permanent: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.enseignantPermanent == 0 ? '0 ' : dataBlock.enseignantPermanent} /></div>
                                    <div className="col-3 relative" style={{color:'#FF647F'}}>
                                        F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.enseignantPermanentFeminin == 0 ? '0 ' : dataBlock.enseignantPermanentFeminin} />
                                    </div>
                                    <div className="col-4 relative text-primary">
                                        G: <Badge className="absolute right-0 mr-2" value={dataBlock.enseignantPermanentMasculin == 0 ? '0 ' : dataBlock.enseignantPermanentMasculin} />
                                    </div>

                                    <div className="col-5 relative">Enseignant vacataire: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.enseignantVacataire == 0 ? '0 ' : dataBlock.enseignantVacataire} /></div>
                                    <div className="col-3 relative" style={{color:'#FF647F'}}>
                                        F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.enseignantVacataireFeminin == 0 ? '0 ' : dataBlock.enseignantVacataireFeminin} />
                                    </div>
                                    <div className="col-4 relative text-primary">
                                        G: <Badge className="absolute right-0 mr-2" value={dataBlock.enseignantVacataireMasculin == 0 ? '0 ' : dataBlock.enseignantVacataireMasculin} />
                                    </div>
                                </div>

                                <div className="md:col-6 lg:col-6 sm:col-12 ">
                                    <div className="card-container flex align-items-center">
                                        <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '170px' }} />

                                        <Chart type="pie" data={pieChartDataEduc} options={lightOptions} style={{ position: 'relative', width: '170px' }} />

                                        <Chart type="pie" data={pieChartDataPersEns} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 card text-2xl font-bold text-center text-red-100">VOUS UTILISEZ <span className="text-pink-800">POULS-SCOLAIRE</span>, Votre indicateur de PERFORMANCE</div>
            </div>

        </>

    );
}

export default DashBoardFondateur;


