import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { PeriodeService } from '../service/PeriodeService';
import { NoteService } from '../service/NoteService';
import { ClasseMatierePeriodeService } from '../service/ClasseMatierePeriodeService';
import { Checkbox } from 'primereact/checkbox';
import { ClasseEleveMatiereService } from '../service/ClasseEleveMatiereService';
import { InputText } from "primereact/inputtext";
import { ClasseService } from "../service/ClasseService";


const MoyennesEleveParent = () => {

  let emptyClasse = {
    id: null,
    libelle: ''
  }

  let emptyPeriode = {
    id: null
  }

  let emptyMatiere = {
    id: null
  }

  const toast = useRef(null);

  const [loading, setLoading] = useState(true);

  const [globalFilter, setGlobalFilter] = useState(null);

  const dt = useRef(null);

  const [matiere, setMatiere] = useState(emptyMatiere);

  const [matieres, setMatieres] = useState([]);

  const [periode, setPeriode] = useState(emptyPeriode);

  const [periodes, setPeriodes] = useState([]);

  const [classes, setClasses] = useState([]);

  const [classe, setClasse] = useState(emptyClasse);

  const [submitted, setSubmitted] = useState(null);

  const [searchBtnLbl, setSearchBtnLbl] = useState("Rechercher");

  const [isSearching, setIsSearching] = useState(false);

  const [isNotSpinning, setIsNotSpinning] = useState('hidden');

  const userId = sessionStorage.getItem('CandidatId');

  const annee = sessionStorage.getItem('AnneEncours');

  const [isClassedTab, setIsClassedTab] = useState([]);

  const [moyenneEleves, setMoyenneEleves] = useState(null);

  const [saveFlag, setSaveFlag] = useState(true);

  const [hiddenFlatUpdate, setHiddenFlatUpdate] = useState(true);
  const [matricule, setmatricule] = useState('');


  useEffect(() => {
    const periodeService = new PeriodeService();
    periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(res => setPeriodes(res));

  }, []);

  useEffect(() => {

  }, []);

  const setCheckedIsClassed = (rowData, val) => {
    let _isClassedTab = [...isClassedTab];
    let _rankObj = { ..._isClassedTab[rowData.getIndex] };
    let classeEleveMatiereService = new ClasseEleveMatiereService();
    val == false ? _rankObj.isClassed = 'N' : _rankObj.isClassed = 'O';

    //Effectuer l'enregistrement après chaque action de l'état de classement de l'élève
    classeEleveMatiereService.marquageClassement(_rankObj).then(res => {
      console.log(res);
      // _rankObj.isClassed = res;
      _isClassedTab[rowData.getIndex] = _rankObj;
      setIsClassedTab(_isClassedTab);
      // setSaveFlag(true);
      setSearchBtnLbl("Actualiser");
      // setHiddenFlatUpdate(false);

    }).catch((error) => {
      console.log("except");
      console.log(error.response.data);
      toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data, life: 5000 });
    }).finally(() => {
      setSaveFlag(true);
      setHiddenFlatUpdate(false);
    })
  }
    ;
  const onSelectClasseChange = (e, name) => {
    let classeId = (e.target && e.target.value) || '';
    let _classe = { ...classe };

    if (name !== null)
      _classe[`${name}`].id = classeId;
    else
      _classe.id = classeId;
    setClasse(_classe);

  }

  const onSelectPeriodeChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _periode = { ...periode };
    if (name !== null)
      _periode[`${name}`].id = val;
    else
      _periode.id = val;
    setPeriode(_periode);
  }

  const search = () => {
    if (matricule) {

      setSubmitted(true);
      let _isClassedTab = [];
      if (classe.id && periode.id) {
        setIsSearching(true);
        setIsNotSpinning('');
        let noteService = new NoteService();
        noteService.getEleveNotesDto(matricule, classe.id, annee, periode.id).then(res => {
          setMoyenneEleves(res);
          setIsClassedTab(_isClassedTab);
          setSearchBtnLbl("Rechercher");
          setLoading(false);
          
          setIsSearching(false);
          setIsNotSpinning('hidden');
        }
        ).catch((error) => {
          if (error.response) {
            console.log('erreur response- pouls-scolaire' + error.response.status);
          } else if (error.request) {
            console.log('erreur request- pouls-scolaire' + error.request);
          } else {
            console.log('Error autres', error.message);
          }
          toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message, life: 10000 })
          setIsSearching(false);
          setIsNotSpinning('hidden');
        });
      }
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Veuillez renseigner les champs',
        life: 3000
      });
    }

  }

  const noteBodyTemplate = (rowData) => {
    let notes = [];
    return (
      <div >
        {rowData.notes.join(' | ')}
      </div>
    );
  }

  const onInputChange = (e) => {
    console.log(e)
    setmatricule(e.target.value);
    const classeService = new ClasseService();
    classeService.getListStudentClasseIdLibelleByMatriculeAndAnnee(e.target.value, sessionStorage.getItem('AnneEncours')).then(res => {
      let classeList = [];
      if (res && res.length > 0) {
        classeList = res.filter((c => c.ecoleId == sessionStorage.getItem('EcoleID') || c.ecoleId == null));
      }
      setClasses(classeList);
    })

  }

  const identite = (obj) => {
    return (
      <div className='grid'>
        <div className='col-12'>
          <h3>Identité de l&apos;élève</h3>
        </div>
        <div className='col-10'>
          <p className={obj?.nom ? '' : 'hidden'}><span className='font-bold'>Nom:</span> {obj?.nom}</p>
          <p className={obj?.nom ? '' : 'hidden'}><span className='font-bold'>Prenoms:</span>{obj?.prenom} </p>
        </div>
        <div className='col-2'>
        </div>
      </div>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className="col-12">
            <div className="formgroup-inline">
              <div className="col-12">
                <label><h3><b>Suivi des notes par matière</b></h3></label>
              </div>
            </div>
            <div className="card grid">
              <div className='row align-items-center'>
                <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                  <label className="mx-3" htmlFor="matricule">Matricule élève</label>
                  <InputText id="matricule" type="text" className='p-inputtext-sm' value={matricule} style={{ width: '250px' }} onChange={(e) => onInputChange(e)} />
                </div>
                <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                  <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
                  <Dropdown id="classe" value={classe.id} onChange={(e) => onSelectClasseChange(e, null)} options={classes}
                    required optionValue="id" optionLabel="libelle" placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(classe.id) })} />
                </div>
                <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                  <label className="mx-3" htmlFor="Période"><b>Période</b></label>
                  <Dropdown id="periode" value={periode.id} onChange={(e) => onSelectPeriodeChange(e, null)} options={periodes}
                    required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(matiere.id) })} />
                </div>

                <div className="col-12 md:col-3 lg:col-3 lg:mb-0 mt-3">
                  <Button label={searchBtnLbl} icon="pi pi-search" disabled={isSearching} className="p-button-success mr-2" onClick={search} />
                  <ProgressSpinner style={{ width: '30px', height: '30px' }} className={isNotSpinning} disabled={isSearching} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
              </div>
            </div>
          </div>
          <Toast ref={toast} />
          <div className="card">
            <DataTable value={moyenneEleves?.list} header={identite(moyenneEleves)} emptyMessage='Aucune donnée' responsiveLayout="scroll">
              <Column field="matiereLibelle" header="Matière" style={{ width: '20em' }}></Column>
              <Column className='border-1' body={noteBodyTemplate} header="Notes" ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

const comparisonFn = function (prevProps, nextProps) {
  // return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MoyennesEleveParent, comparisonFn);
