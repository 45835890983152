import React, {useState, useEffect, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from 'primereact/autocomplete';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Messages } from 'primereact/messages';

import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import {InscriptionService} from '../../service/operations/InscriptionsService';
import {ParentService} from '../../service/parametre/ParentService';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import classNames from "classnames";
 import * as XLSX from "xlsx";
import {MatiereService} from "../../service/parametre/MatiereService";
import {Toolbar} from "primereact/toolbar";
import {EleveService} from "../../service/operations/EleveService";
import {ProgressSpinner} from "primereact/progressspinner";
import {BrancheService} from "../../service/BrancheService";


const ImportEleve = () => {
    let emptyeleve = {
        eleve_mail :'' ,
        eleve_numero_extrait_naiss :0,
        eleveadresse:'',
        elevecellulaire :'',
        elevecode :'',
        elevedate_etabli_extrait_naiss :null,
        elevedate_naissance :null,
        idTenant : 0,
        identifiantEleve : 0,
        elevelieu_etabliss_etrait_naissance :'',
        elevelieu_naissance:'',
        elevenom:'',
        eleveprenom:'' ,
        eleve_matricule_national:''

    };
    let arrayList = [] ;
    let emptyparent = {
        parentid :null ,
        parentcode :'',
        parentnom:'',
        parentprenom :'',
        parent_tel :'',
        parent_tel2 :'',
        parent_profession :'',
        parentemail : '',
        identifiantTypeParent : 0,
        elevelieu_etabliss_etrait_naissance :'',
        identifiantTenant:''

    };
    let emptyColomn= {
        N:'',
        ID:'',
        matricule:'',
        nom:'',
       prenoms:'',
        statut:'',
        sexe:'',
        red:'',
        lv2:'',
        dateNaissance:'',
        lieuNaissance:'',
        nationalite:'',
        regime:'',
        dateInscription:'',
        contact:'',
        contact2:'',
        classe:'',
        identifiant_classe:null,
        identifiantBranche:'',
        libelleBranche:'',
        Niveau:'',
        ivoirien:'',
        etranger_africain:'',
        etranger_non_africain:''


      //  date_convertJson: JSON.parse('37307')
    }

    let emptyColomn2= {
        N:'',
        id_eleve:'',
        matricule:'',
        nom:'',
        prenoms:'',
        statut:'',
        sexe:'',
        red:'',
        lv2:'',
        datenaissance:'',
        branche:'',
        lieun:'',
        nationalite:'',
        adresse:'',
        mobile:'',
        mobile2:'',
        pere:'',
        mere:'',
        tuteur:'',
        redoublant:'',
        regime:'',
        decision_ant:'',
        extrait_numero:'',
        identifiant_classe:null,
        identifiantBranche:'',
        libelleBranche:'',
        Niveau:'',
        extrait_date:'',
        extrait_lieu:'',
        decision_aff:''

        //  date_convertJson: JSON.parse('37307')
    }


    let emptyIdentification = {
        inscriptionsid :null ,
        inscriptionscode :'',
      inscriptions_ecole_origine:'',
       inscriptions_classe_precedente :'',
       inscriptions_derniereclasse_religieuse :'',
        inscriptions_classe_actuelle :'',
         inscriptions_status :'',
      inscriptionsdate_creation : Date.now(),
      identifiantEleve : 0,
      identifiantEcole :0,
        identifiantAnnee_scolaire:0,
       libellehandicap:[]

    };
    let emptyAnneeScoliare= {
        annee_scolaireid: 1 ,
        annee_scolaire_libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:''
    } ;
    let emptybranche= {
        id: null ,
        libelle: ''
    } ;
    const [columns, setColumns] = useState(null);
    const [column, setColumn] = useState(emptyColomn);
    const [fileName, setFileName] = useState(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);

    const [createProductsDialog , setCreateProductsDialog] = useState(false);
    const [updateProductsDialog , setUpdateProductsDialog] = useState(false);

    const [retourApi, setretourApi]  = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [importedData, setImportedData] = useState([]);
    const [selectedImportedData, setSelectedImportedData] = useState([]);
    const [importedCols, setImportedCols] = useState([{ field: '', header: 'Header' }]);
    const dt = useRef(null);
    const [countries, setCountries] = useState([]);
    const [handicaps, setHandicaps] = useState([]);
    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);
   const [anneeEncours, setAnneeEncours] = useState(sessionStorage.getItem('AnneEncours'));
   // const [anneeEncours, setAnneeEncours] = useState(51);
    const [inscriptions, setinscriptions] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [handicapDialog, sethandicapDialog] = useState(false);
    const [modif, setModif]  = useState(null);
    const [inscription, setinscription]  = useState(emptyIdentification);
    const [eleve, seteleve]  = useState(emptyeleve);
    const [parent, setparent]  = useState(emptyparent);
    const [selectedTypeParent, setSelectedTypeParent] = useState(null);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [isNotSpinning1, setIsNotSpinning1] = useState('hidden');
    const [branches, setBranches] = useState(null);
    const [branche, setBranche] = useState(emptybranche);
    const [contentMess, setContentMess] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [submittedHandicap, setSubmittedHandicap] = useState(false);

    const [filteredinscriptions, setFilteredinscriptions] = useState(null);
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [checkboxValue, setCheckboxValue] = useState([]);
    const [radioValue, setRadioValue] = useState('non');
    const msgs = useRef(null);
    const msgs2 = useRef(null);
    const msgs3 = useRef(null);
    const toast = useRef(null);

    const [newData, setNewData] = useState(null);

    const TypeParents = [
        {type_parentlibelle: 'PERE', type_parentcode: 'PERE'},
        {type_parentlibelle: 'MERE', type_parentcode: 'MERE'},
        {type_parentlibelle: 'TUTEUR', type_parentcode: 'TUTEUR'},
        {type_parentlibelle: 'PERSONNE EN CHARGE', type_parentcode: 'PERSONNE EN CHARGE'}

    ];

    const onTypeParentChange = (e) => {
        setSelectedTypeParent(e.value);
    }




    useEffect(() => {
        const brancheService = new BrancheService();
        brancheService.getByNiveauEnseigment(sessionStorage.getItem('EcoleID')).then(resp => setBranches(resp));
        const eleveService = new EleveService();
        eleveService.AnneeScolaire(tenant).then(data => {
            console.log(data)
            setanneeScolaire(data)
        });

        msgs3.current.show(
            { sticky: true, severity: 'warn', summary: '', detail: ' Les dates doivent être sous le format 01/01/1900', closable: false }
        );


    }, []);

 const  onRadioChange = (e) => {
        setRadioValue(e.value);
     setHandicaps([]) ;
     setSubmittedHandicap(false) ;
     // setSubmitted(false);
     sethandicapDialog(true) ;
    }

    useEffect(()=>{
        if(modif===1){
            setShowMessage(true);
        }
    },[modif])


    const createSelectedProducts = () => {




        setIsNotSpinning('');
        let _products = columns.filter(val => !selectedProducts.includes(val));
        setColumns(_products);
        console.log("Tableau+++ "+selectedProducts) ;
        const eleveService = new EleveService();
       // anneeEncours
        const newData1 = selectedProducts.map((item) => {

            if (item.matricule === 'NULL') {
                item.matricule = "";
            }
            if (item.nom === 'NULL') {
                item.nom = "";
            }
            if (item.prenoms === 'NULL') {
                item.prenoms ="";
            }
            if (item.statut === 'NULL') {
                item.statut ="";
            }
            if (item.nationalite === 'NULL') {
                item.nationalite ="";
            }
            if (item.lv2 === 'NULL') {
                item.lv2 ="";
            }
            if (item.datenaissance === 'NULL'||item.datenaissance === '') {
                item.datenaissance ='01/01/1900';
            }

            if (item.lieun === 'NULL') {
                item.lieun ="";
            }
            if (item.nationalite === 'NULL') {
                item.nationalite ="";
            }
            if (item.adresse === 'NULL') {
                item.adresse ="";
            }
            if (item.mobile === 'NULL') {
                item.mobile ="";
            }
            if (item.mobile2 === 'NULL') {
                item.mobile2 ="";
            }
            if (item.pere === 'NULL') {
                item.pere ="";
            }
            if (item.mere === 'NULL') {
                item.mere ="";
            }
            if (item.tuteur === 'NULL') {
                item.tuteur ="";
            }
            if (item.redoublant === 'NULL') {
                item.redoublant ="";
            }
            if (item.regime === 'NULL') {
                item.regime ="";
            }

            if (item.decision_ant === 'NULL') {
                item.decision_ant ="";
            }
            if (item.extrait_numero === 'NULL') {
                item.extrait_numero ="";
            }
            if (item.extrait_date === 'NULL'||item.extrait_date === '') {
                item.extrait_date ='01/01/1900';
            }
            if (item.extrait_lieu === 'NULL') {
                item.extrait_lieu ="";
            }
            if (item.decision_ant === 'NULL') {
                item.decision_ant ="";
            }
            if (item.decision_aff === 'NULL') {
                item.decision_aff ="";
            }

            if (item.ivoirien === 'NULL') {
                item.ivoirien ="";
            }

            if (item.etranger_africain === 'NULL') {
                item.etranger_africain ="";
            }

            if (item.etranger_non_africain === 'NULL') {
                item.etranger_non_africain ="";
            }

            return item;
        });



        eleveService.importerIEtCreerEleves(tenant,anneeEncours,typeOperation1,branche.id,newData1).then(data => {
            console.log(data)
            setretourApi(data)
            setCreateProductsDialog(false);
            setSelectedProducts(null);
            setModif(1);
            setIsNotSpinning('hidden');
              }).catch((error) => {
            if (error.response) {
                console.log('erreur response-' + error.response.status);

                setContentMess(error.response.data) ;
                console.log("Content Message "+error.response.data);

              toast.current.show({ severity: 'error', summary: 'Attention', detail: error.response.data, life: 50000 });
                setCreateProductsDialog(false);
                setSelectedProducts(null);
                setIsNotSpinning('hidden');
            } else if (error.request) {
                console.log('erreur request- ' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
        });
    }

    const updateSelectedProducts = () => {
        setIsNotSpinning1('');
        let _products = columns.filter(val => !selectedProducts.includes(val));
        setColumns(_products);
        console.log("Tableau+++ "+selectedProducts) ;
        const eleveService = new EleveService();
        const newData1 = selectedProducts.map((item) => {

            if (item.matricule === 'NULL') {
                item.matricule = "";
            }
            if (item.nom === 'NULL') {
                item.nom = "";
            }
            if (item.prenoms === 'NULL') {
                item.prenoms ="";
            }
            if (item.statut === 'NULL') {
                item.statut ="";
            }
            if (item.nationalite === 'NULL') {
                item.nationalite ="";
            }
            if (item.lv2 === 'NULL') {
                item.lv2 ="";
            }
            if (item.datenaissance === 'NULL'||item.datenaissance === '') {
                item.datenaissance ='01/01/1900';
            }

            if (item.lieun === 'NULL') {
                item.lieun ="";
            }
            if (item.nationalite === 'NULL') {
                item.nationalite ="";
            }
            if (item.adresse === 'NULL') {
                item.adresse ="";
            }
            if (item.mobile === 'NULL') {
                item.mobile ="";
            }
            if (item.mobile2 === 'NULL') {
                item.mobile2 ="";
            }
            if (item.pere === 'NULL') {
                item.pere ="";
            }
            if (item.mere === 'NULL') {
                item.mere ="";
            }
            if (item.tuteur === 'NULL') {
                item.tuteur ="";
            }
            if (item.redoublant === 'NULL') {
                item.redoublant ="";
            }
            if (item.regime === 'NULL') {
                item.regime ="";
            }

            if (item.decision_ant === 'NULL') {
                item.decision_ant ="";
            }
            if (item.extrait_numero === 'NULL') {
                item.extrait_numero ="";
            }
            if (item.extrait_date === 'NULL'||item.extrait_date === '') {
                item.extrait_date ='01/01/1900';
            }
            if (item.extrait_lieu === 'NULL') {
                item.extrait_lieu ="";
            }
            if (item.decision_ant === 'NULL') {
                item.decision_ant ="";
            }
            if (item.decision_aff === 'NULL') {
                item.decision_aff ="";
            }

            if (item.ivoirien === 'NULL'||item.ivoirien === '') {
                item.ivoirien ="OUI";
            }

            if (item.etranger_africain === 'NULL'||item.etranger_africain === '') {
                item.etranger_africain ="NON";
            }

            if (item.etranger_non_africain === 'NULL'||item.etranger_non_africain === '') {
                item.etranger_non_africain ="NON";
            }


            return item;
        });

        eleveService.importerIEtUpdateEleves(tenant,anneeEncours,typeOperation1,branche.id,newData1).then(data => {
            console.log(data)
            setretourApi(data)
            setUpdateProductsDialog(false);
            setSelectedProducts(null);
            setModif(1);
            setIsNotSpinning('hidden');
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response-' + error.response.status);

                setContentMess(error.response.data) ;
                console.log("Content Message "+error.response.data);

                toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.response.data, life: 50000 });
                setUpdateProductsDialog(false);
                setSelectedProducts(null);
                setIsNotSpinning('hidden');
            } else if (error.request) {
                console.log('erreur request- ' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
        });
    }
    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        setCheckboxValue(selectedValue);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

    }
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;

    }
    const openNew = () =>{
        console.log("Branche "+branche.id)
        if(branche.id){
            setCreateProductsDialog(true);
        } else {
 toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Veuillez selectionner la branche', life: 3000 });
        }

    }

    const update = () =>{
        setUpdateProductsDialog(true);
    }

    const openNewHandicap = () => {
        //setparent(emptyparent);
      setRadioValue('oui')
       setHandicaps([]) ;
        setSubmittedHandicap(false) ;
       // setSubmitted(false);
        sethandicapDialog(true) ;
       // setProductDialog(true);
    }

    const saveParent = () => {
        setSubmitted(true);
        if (parent.parentnom.trim()&& parent.parentprenom.trim()&&parent.parent_tel.trim()) {
            const parentService = new ParentService();
            parentService.creerParent(parent) ;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            setProductDialog(false);

        }
    }

    const saveHandicap = () => {
        setSubmittedHandicap(true);
        setHandicaps(checkboxValue);

    }
    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const clear = () => {
        setImportedData([]);
        setSelectedImportedData([]);
        setImportedCols([{ field: '', header: 'Header' }]);
    }

    const onImportSelectionChange = (e) => {
        setSelectedImportedData(e.value);
        const detail = e.value.map(d => Object.values(d)[0]).join(', ');
        toast.current.show({ severity: 'info', summary: 'Data Selected', detail, life: 3000 });
    }

    const onSelectionChange = (e) => {
        setSelectedProducts(e.value);
    }
    const hideCreateProductsDialog = () => {
        setCreateProductsDialog(false);
    };

    const createProductsDialogFooter = (
        <>
            <div className="field">
                <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideCreateProductsDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={createSelectedProducts} />
        </>
    );

    const hideUpdateProductsDialog = () => {
        setUpdateProductsDialog(false);
    };
    const updateProductsDialogFooter = (
        <>
            <div className="field">
                <ProgressSpinner className={isNotSpinning1}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdateProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={updateSelectedProducts} />
        </>
    );



    const searchCountry = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < countries.length; i++) {
            const country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        }
        setFilteredinscriptions(filtered);
    };
 const handleFile = async (e)=>{
     const file= e.target.files[0];
     setFileName(file.name) ;
     const data = await file.arrayBuffer();
     const workbook = XLSX.read(data,{type:'binary',cellText:false,cellDates:true});
     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
     const jsonData = XLSX.utils.sheet_to_json(worksheet,{raw:false,dateNF:'dd/MM/yyyy'});
      setColumns(jsonData);
   console.log("jsonData "+JSON.stringify(columns)) ;

     console.log("DateConvert "+JSON.stringify(columns)) ;




 } ;



    const nomBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Nom </span>
                {rowData.nom===''||rowData.nom==='NULL'? "":rowData.nom}
            </>
        );
    }

    const prenomsBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Prénoms </span>
                {rowData.prenoms===''||rowData.prenoms==='NULL'? "":rowData.prenoms}
            </>
        );
    }

    const statutBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">statut </span>
                {rowData.statut===''||rowData.statut==='NULL'? "":rowData.statut}
            </>
        );
    }

    const sexeBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">sexe </span>
                {rowData.sexe===''||rowData.sexe==='NULL'? "":rowData.sexe}
            </>
        );
    }

    const lv2BodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">lv2 </span>
                {rowData.lv2===''||rowData.lv2==='NULL'? "":rowData.lv2}
            </>
        );
    }

    const datenaissanceBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">datenaissance </span>
                {rowData.datenaissance===''||rowData.datenaissance==='NULL'? "":rowData.datenaissance}
            </>
        );
    }

    const brancheBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">branche </span>
                {rowData.branche===''||rowData.branche==='NULL'? "":rowData.branche}
            </>
        );
    }

    const lieunBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">lieu de naissance </span>
                {rowData.lieun===''||rowData.lieun==='NULL'? "":rowData.lieun}
            </>
        );
    }


    const adresseBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">adresse </span>
                {rowData.adresse===''||rowData.adresse==='NULL'? "":rowData.adresse}
            </>
        );
    }

    const mobileBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">mobile </span>
                {rowData.mobile===''||rowData.mobile==='NULL'? "":rowData.mobile}
            </>
        );
    }

    const mobile2BodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">mobile2 </span>
                {rowData.mobile2===''||rowData.mobile2==='NULL'? "":rowData.mobile2}
            </>
        );
    }

    const pereBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">pere </span>
                {rowData.pere===''||rowData.pere==='NULL'? "":rowData.pere}
            </>
        );
    }

    const mereBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">mere </span>
                {rowData.mere===''||rowData.mere==='NULL'? "":rowData.mere}
            </>
        );
    }

    const tuteurBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">tuteur </span>
                {rowData.tuteur===''||rowData.tuteur==='NULL'? "":rowData.tuteur}
            </>
        );
    }

    const redoublantBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">redoublant </span>
                {rowData.redoublant===''||rowData.redoublant==='NULL'? "":rowData.redoublant}
            </>
        );
    }

    const regimeBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">regime </span>
                {rowData.regime===''||rowData.regime==='NULL'? "":rowData.regime}
            </>
        );
    }

    const decision_antTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">decision_ant </span>
                {rowData.decision_ant===''||rowData.decision_ant==='NULL'? "":rowData.decision_ant}
            </>
        );
    }

    const extrait_numeroBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">extrait_numero </span>
                {rowData.extrait_numero===''||rowData.extrait_numero==='NULL'? "":rowData.extrait_numero}
            </>
        );
    }
    const onSelectBrancheChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        // console.log(val);
        let _branche = { ...branche };

        if (name !== null)
            _branche[`${name}`].id = val;
        else
            _branche.id = val;

        setBranche(_branche);
    }
    const extrait_dateBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">extrait_date </span>
                {rowData.extrait_date===''||rowData.extrait_date==='NULL'? "":rowData.extrait_date}
            </>
        );
    }

    const extrait_lieuBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">extrait_lieu </span>
                {rowData.extrait_lieu===''||rowData.extrait_lieu==='NULL'? "":rowData.extrait_lieu}
            </>
        );
    }

    const decision_affBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">decision_aff </span>
                {rowData.decision_aff===''||rowData.decision_aff==='NULL'? "":rowData.decision_aff}
            </>
        );
    }

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <input type="file" onChange={(e)=> handleFile(e)} ></input>
                               </div>
                <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
                    <label className="mx-3" htmlFor="branche"><b>Branche</b></label>
                    <Dropdown id="branche" value={branche.id} onChange={(e) => onSelectBrancheChange(e, null)} options={branches}
                              required optionValue="id" optionLabel="libelle" style={{ height: '30px' }} placeholder="Selectionner la branche" className={'col-8 p-0 ' + classNames({ 'p-invalid': submitted && !(branche.id) })} />
                </div>

            </React.Fragment>

        )
    }

    const DateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {rowData.date_convertJson}
            </>
        );
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>


                <Button label="Importer" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} disabled={!selectedProducts || !selectedProducts.length}/>

                <Button label="Modifier" icon="pi pi-pencil" className="p-button-success mr-2" onClick={update} disabled={!selectedProducts || !selectedProducts.length}/>

            </React.Fragment>
        )
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideHandiDialog = () => {
        setSubmittedHandicap(false);

        sethandicapDialog(false)
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveParent} />
        </>
    );

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de Naissance </span>
                {rowData.datenaissance==null?"":rowData.datenaissance}
            </>
        );
    }
    const matriculeBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Matricule</span>
                {rowData.matricule===''||rowData.matricule==='NULL'? "":rowData.id_eleve}
            </>
        );
    }
    const extraiDateBodyTemplate = (rowData) => {

        const extraiD = rowData.extrait_date ;
        const cellStyle ={
            color:extraiD===''||extraiD==='NULL'?'red' :'black'
        }

        return (
            <>
                <span className="p-column-title">Date extrait</span>
                <span style={cellStyle}> {rowData.extrait_date==='NULL'? "":rowData.extrait_date} </span>
            </>
        );
    }


    const handicapDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHandiDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHandicap} />
        </>
    );
    return (


            <div className="grid crud-demo">

                <div className="col-12">
                    <Messages ref={msgs2} />
                </div>
                <div className="col-12">
                    <Messages ref={msgs3} />
                </div>
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
     {  <DataTable value={columns} responsiveLayout="scroll" ref={dt} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="matricule" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                <Column field="id_eleve" header="Code interne"></Column>
                <Column field="matricule" header="matricule" ></Column>
                <Column field="nom" header="nom" ></Column>
                <Column field="prenoms" header="prenoms" ></Column>
                 <Column field="statut" header="statut" ></Column>
                <Column field="nationalite" header="nationalite" ></Column>
                <Column field="ivoirien" header="ivoirien" ></Column>
                <Column field="etranger_africain" header="etranger_africain" ></Column>
              <Column field="etranger_non_africain" header="etranger_non_africain" ></Column>
                 <Column field="sexe" header="sexe" ></Column>
                 <Column field="lv2" header="lv2"></Column>
                 <Column field="datenaissance" header="datenaissance" ></Column>
                  <Column field="branche" header="branche"></Column>
                 <Column field="lieun" header="lieun" ></Column>
                 <Column field="adresse" header="adresse"></Column>
                 <Column field="mobile" header="mobile" ></Column>
                 <Column field="mobile2" header="mobile2" ></Column>
                 <Column field="pere" header="pere" ></Column>
                 <Column field="mere" header="mere" ></Column>
                 <Column field="tuteur" header="tuteur" ></Column>
                 <Column field="redoublant" header="redoublant" ></Column>
                  <Column field="regime" header="regime" ></Column>
                 <Column field="decision_ant" header="decision_ant" ></Column>
                  <Column field="extrait_numero" header="extrait_numero"  ></Column>
                   <Column field="extrait_date" header="extrait_date" ></Column>
                     <Column field="extrait_lieu" header="extrait_lieu" ></Column>
                     <Column field="decision_aff" header="decision_aff" ></Column>

     </DataTable>}
                        <Dialog visible={createProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={createProductsDialogFooter} onHide={hideCreateProductsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                { <span>Voulez vous faire une demande d'inscription pour ces élèves selectionnés</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={updateProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={updateProductsDialogFooter} onHide={hideUpdateProductsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                { <span>Voulez vous modifier les informations de ces élèves sélectionnés ?</span>}
                            </div>
                        </Dialog>



                        <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                            <div className="flex justify-content-center flex-column pt-6 px-3">
                                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                                <h5>{"Demande d'inscription"}</h5>
                                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                    {retourApi}
                                </p>
                            </div>
                        </Dialog>
                    </div>
                </div>

            </div>


    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ImportEleve, comparisonFn);
